.container-debug {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;

	.container {
		height: 100%;
		border-left: 1px solid red;
		border-right: 1px solid red;
		z-index: 99;

		@include respond-from($device-xs, none) {
			max-width: 540px;
		}

		@include respond-from($device-sm, none) {
			max-width: 720px;
		}

		@include respond-from($device-md, none) {
			max-width: 960px;
		}

		@include respond-from($device-lg, none) {
			max-width: 1140px;
		}

		@include respond-from($device-xl, none) {
			max-width: 1330px;
		}
	}

}
