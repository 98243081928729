@charset "utf-8";

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&display=swap');

@each $font-face in 'GalanoGrotesque-Regular',
'GalanoGrotesque-Bold',
'GalanoGrotesque-Medium',
	{

	// 'Circular-Std-Book', 'Circular-Std-Bold'
	@font-face {
		font-family: $font-face;
		font-style: normal;
		font-weight: normal;
		src: url('../fonts/#{$font-face}.svg');
		src: url('../fonts/#{$font-face}.eot?') format('eot'),
		url('../fonts/#{$font-face}.woff') format('woff'),
		url('../fonts/#{$font-face}.woff2') format('woff2'),
		url('../fonts/#{$font-face}.ttf') format('truetype');
	}
}

//fonts
$font-p: 'GalanoGrotesque-Regular',
sans-serif;
$font-b: 'GalanoGrotesque-Bold',
sans-serif;
$font-m: 'GalanoGrotesque-Medium',
sans-serif;
/* @mixin font($s) {
	@if($s=='p') {
		font-weight: 400;
	}

	@if($s=='h') {
		font-weight: 700;
	}

	@if($s=='d') {
		font-weight: 900;
	}
} */

//colors
$theme-color: #1A46BB;
$secondary-color: #2A3B50;
$text-color: #000;
$text-dark: #222231;
$text-light: #7C7C7C;
$bg-color: #F5F6FA;
$bg-dark: #fff;
$bg-light: #eff1f6;
$bg-wall: #35405D;

$danger: #E03C3C;
$warning: #F2A853;
$success: #57C973;

$spacing-unit: 30px;

// $devices
$device-xs: 540px;
$device-sm: 768px;
$device-md: 992px;
$device-lg: 1200px;
$device-xl: 1360px;

// unit classes
$unit-classes: (0, 5, 10, 15, 20, 25, 30, 40, 50, 60);

@mixin respond-to($min,
	$max) {
	@if $min==none {
		@media (max-width: $max - 1) {
			@content;
		}
	}

	@else if $max==none {
		@media (min-width: $min) {
			@content;
		}
	}

	@else {
		@media (min-width: $min) and (max-width: $max - 1) {
			@content;
		}
	}
}

@mixin respond-from($min, $max) {
	@if $min==none {
		@media (max-width: $max - 1) {
			@content;
		}
	}

	@else if $max==none {
		@media (min-width: $min) {
			@content;
		}
	}

	@else {
		@media (min-width: $min) and (max-width: $max - 1) {
			@content;
		}
	}
}

@mixin transform($transformation) {
	transform: $transformation;
	-webkit-transform: $transformation;
	-moz-transform: $transformation;
	-ms-transform: $transformation;
	-o-transform: $transformation;
}


@mixin text-align($rule, $breakpoint, $fallback) {
	text-align: $rule;

	@include respond-to(max, $breakpoint) {
		text-align: $fallback;
	}
}

@mixin btn($bgc, $color, $lighting: 'darken', $ammount: 4,
	$padding: 16px 30px) {
	@if $bgc !='none' {
		background-color: $bgc;
	}

	@if $color !='none' {
		color: $color;
	}

	@if $padding !='none' {
		padding: $padding
	}

	&:hover,
	&:focus {
		@if($lighting=='darken') {
			@if $bgc !='none' {
				background-color: darken($bgc, $ammount);
			}

			@if $color !='none' {
				color: darken($color, $ammount/2);
			}
		}

		@if ($lighting=='lighten') {
			@if $bgc !='none' {
				background-color: lighten($bgc, $ammount);
			}

			@if $color !='none' {
				color: lighten($color, $ammount/2);
			}
		}
	}
}

@mixin clearfix() {
	&:after {
		content: "";
		display: table;
		clear: both;
	}
}

@function rem($rem) {
	// @return math.div($rem, 16px)+rem;
	@return ($rem/16px)+rem;
}

@import "base",
"common",
"blocks",
"components",
"layout",
"_page",
"_home",
"_debug";
