/* --------------------------------
 *  Components
 *  for interactive components
 *  variants: --default --light
 -------------------------------- */
.c-accordion {
	$c: '.c-accordion';

	#{$c}__item {
		padding-bottom: 5px;
		padding-top: 5px;

		&:last-of-type {
			margin-bottom: 0;
			padding-bottom: 3px;
		}
	}

	#{$c}__title {
		margin-right: auto;

        div{
            display: flex;
            align-items: center;
            gap: 5px;
        }

        span{
            color: #8F9094;
            font-size: 12px;
            font-weight: 500;

            b{
                font-weight: 500;
                color: #707175;
            }
        }
	}

	#{$c}__head {
		display: flex;
		align-items: center;
		cursor: pointer;

		.left {
			flex: 1;
			display: flex;
			align-items: center;

			p {
				margin: 0;
			}

			img {
				max-width: 60px;
			}
		}
	}

	#{$c}__toggle {
		color: $theme-color;
	}

	#{$c}__body {
		// transition: all 0.2s ease-in;
		padding: 5px 0 0;
		overflow: hidden;

		p {
			&:last-of-type {
				margin-bottom: 0;
			}
		}

		table {
			width: 100%;
			font-size: 13px;

			th {
				font-family: $font-m;
				color: #454545;
				background: #E8E7EB;
				font-weight: 400;
				padding: 10px;

				&:first-child {
					border-radius: 2px 0 0 2px;
				}

				&:last-child {
					border-radius: 0 2px 2px 0;
				}
			}

			td {
				padding: 5px;
				border-bottom: 1px solid #E8E7EB;

				&:last-child {
					text-align: right;
				}
			}

			tr {
				&:last-child {
					td {
						border-bottom: 0;
					}
				}
			}
		}
	}

	&--default {
		#{$c}__item {
			padding: 0;
			border: 1px solid #D5D8DD;
			margin-bottom: 6px;

			#{$c}__head {
				.fa-angle-down {
					transform: rotate(0);
				}
			}

			&:last-of-type {
				padding-bottom: 0;
			}

			&.is-open {
				#{$c}__head {
					.fa-angle-down {
						transform: rotate(180deg);
					}
				}
			}
		}

		#{$c}__head {
			padding: 9px 20px;
			background-color: #E8E7EB;
			font-size: rem(16px);
			font-family: $font-m;
		}

		#{$c}__body {
			padding: 15px 20px;
		}

		@media (max-width: $device-xs) {
			#{$c}__body {
				padding: 15px 0px;
			}
		}
	}

	&--light {

		#{$c}__item {
			background-color: #fff;
			padding: 0;
			margin-bottom: 6px;
			border-radius: 3px;

			#{$c}__head {
				.fa-angle-down {
					transform: rotate(0);
				}
			}

			&:last-of-type {
				padding-bottom: 0;
			}

			&.is-open {
				#{$c}__head {
					.fa-angle-down {
						transform: rotate(180deg);
					}
				}
			}
		}

		#{$c}__head {
			padding: 15px 25px;
			font-size: rem(16px);
			font-family: $font-m;
			border-bottom: 1px solid #E8E7EB;
		}

		#{$c}__body {
			padding: 25px 25px;
		}
	}

}

.c-range {
	$c: '.c-range';
	padding: 0 9px;

	#{$c}__input {
		display: flex;
		font-size: rem(12px);
		margin-left: -9px;
		margin-right: -9px;

		>div {
			background-color: rgba(#B3BAC4, 0.2);
			border-radius: 3px;
			display: flex;
			align-items: center;
			padding-left: 5px;

			&:first-of-type {
				margin-right: 5px;
			}
		}

		span {
			color: #8F9094;
		}

		input {
			border: 0;
			background-color: transparent;
			padding: 5px;
			width: 100%;
			outline: none;
		}

		@include respond-from($device-lg, none) {
			input {
				max-width: 75px;
			}
		}
	}

	.ui-slider-horizontal {
		height: 2px;
		border: 0;
		background-color: #8F9094;
		margin-top: 10px;
		margin-bottom: 20px;

		.ui-slider-range {
			background-color: $theme-color;
			height: 2px;
		}

		.ui-slider-handle {
			border-radius: 50%;
			border: 0;
			background-color: $theme-color;
			width: 16px;
			height: 16px;
			top: -7px;
		}
	}
}

.c-tabs {
	$c: '.c-tabs';

	#{$c}__header {
		>ul {
			display: flex;
			list-style: none;
			padding-left: 0;
			margin-left: 0;
			margin-bottom: 0;
			cursor: pointer;
			overflow: auto;

			>li {
				padding: rem(15px) rem(20px);

				&.active {
					background-color: #fff;
					color: $theme-color;
				}
			}
		}
	}

	#{$c}__body {
		background-color: #fff;

		>ul {
			margin-left: 0;
			padding-left: 0;
			list-style: none;

			>li {
				display: none;
				padding: 15px;
				// overflow-x: auto;

				&.active {
					display: block;
				}

				hr {
					margin-top: 30px;
					margin-bottom: 30px;
				}
			}
		}
	}

	@include respond-from($device-sm, none) {

		#{$c}__header {
			>ul {
				>li {
					padding: rem(15px) rem(30px);
				}
			}
		}

		#{$c}__body {
			>ul {
				>li {
					padding: 15px 30px;
				}
			}
		}
	}
}

.c-cart {
	$c: '.c-cart';

	#{$c}__overlay {
		display: none;
		position: fixed;
		right: 0;
		top: 0;
		bottom: 0;
		left: 0;
		z-index: 910;
	}

	#{$c}__container {
		position: fixed;
		right: 0;
		top: 0;
		bottom: 0;
		width: 400px;
		max-width: 80%;
		padding: 80px 20px 0;
		background-color: #fff;
		transform: translateX(100%);
		transition: all 0.3s ease-in;
		z-index: 990;
	}

	&--is-open {
		#{$c}__container {
			transform: translateX(0);
		}

		#{$c}__overlay {
			display: block;
		}

	}

	#{$c}__close {
		position: absolute;
		right: 20px;
		top: 20px;
		font-size: rem(20px);
		color: $theme-color;
		line-height: 1;
		padding: 10px;
		cursor: pointer;
		z-index: 991;
	}

	#{$c}__header {
		position: absolute;
		top: 0;
		left: 20px;
		right: 20px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding-top: 15px;
		height: 80px;
		border-bottom: 1px solid #E8E7EB;
	}

	#{$c}__list {
		position: absolute;
		top: 80px;
		bottom: 120px;
		left: 15px;
		right: 15px;
		margin: 15px 5px 0;
		overflow: auto;
	}

	#{$c}__action {
		position: absolute;
		bottom: 0;
		left: 20px;
		right: 20px;
		height: 120px;
		display: flex;
		flex-flow: column;
		justify-content: center
	}

	#{$c}__empty {
		position: absolute;
		top: 80px;
		bottom: 0;
		width: 100%;
		display: flex;
		flex-flow: column;
		align-items: center;
		justify-content: center;

		p {
			font-family: $font-m;
			font-size: rem(14px);
			text-align: center;
			color: #7B7C7F;
			margin-bottom: 20px;
		}

		img {
			margin-bottom: 16px;
		}
	}

	.h100 {
		height: 100%;
	}
}


.c-box-input {
	position: relative;
	border: 1px dashed #A1A5AB;
	padding: 20px 15px;
	border-radius: 2px;

	label {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		margin-bottom: 0;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	i {
		position: relative;
		top: -2px;
		margin-right: 10px;
	}

	input[type=file] {
		display: none;
	}
}
