/* -----------------------
      html
----------------------- */

html {
	// -webkit-font-smoothing: antialiased;
	font-size: 100%;

	@include respond-from($device-md, $device-lg) {
		font-size: 94%;
	}

	@include respond-from($device-sm, $device-md) {
		font-size: 88%;
	}

	@include respond-from(none, $device-sm) {
		font-size: 82%;
	}

	&[lang=sin] {
		font-size: 80%;

		@include respond-from($device-md, $device-lg) {
			font-size: 78%;
		}

		@include respond-from($device-sm, $device-md) {
			font-size: 74%;
		}

		@include respond-from(none, $device-sm) {
			font-size: 72%;
		}
	}
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
hr,
dl,
dd,
ol,
ul,
figure {
	margin: 0;
	padding: 0;
}

/**
 * Basic styling
 */

body {
	color: $text-color;
	-webkit-text-size-adjust: 100%;
	-webkit-font-feature-settings: "kern"1;
	-moz-font-feature-settings: "kern"1;
	-o-font-feature-settings: "kern"1;
	font-feature-settings: "kern"1;
	font-kerning: normal;
}

.full-container {
	width: calc(100% - 59px);
	margin: 0 auto;
}

/**
 * Set `margin-bottom` to maintain vertical rhythm
 */

h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
ul,
ol,
dl,
figure,
%vertical-rhythm {
	margin-bottom: $spacing-unit / 2;
}

/**
 * Lists
 */

ul,
ol {
	margin-left: $spacing-unit;

}

li {

	>ul,
	>ol {
		margin-bottom: 0;
	}
}

/**
 * Headings
 */

/* h1, h2, h3, h4, h5, h6 {
    font-weight: bold;
} */

/**
 * Links
 */

a {
	color: $theme-color;
	text-decoration: none;

	&:hover {
		color: $text-color;
		text-decoration: underline;
	}
}

/* mixin comp styles */

@each $val in $unit-classes {
	.mb-#{$val} {
		margin-bottom: #{$val}px !important;
	}
}

body {
	font-family: $font-p;
	font-size: rem(14px);
	color: $text-color;
	overflow-x: hidden;
	background-color: #F2F4F7;

	@include respond-from($device-sm, $device-md) {
		// min-width: 992px;
		overflow-x: auto;

		.container,
		.container-md,
		.container-sm {
			// max-width: 960px
		}
	}
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
	font-family: $font-m;
}

h1,
.h1 {
	font-family: $font-b;
	font-size: rem(48px);
	color: #001F8B;
	letter-spacing: rem(2.24px);
	line-height: 1.5;
	margin-bottom: rem(30px);

	@include respond-from(none, $device-sm) {
		font-size: rem(34px);
	}
}

h2,
.h2 {
	font-size: rem(34px);
	color: #001F8B;
	margin-bottom: rem(22px);

	@include respond-from(none, $device-sm) {
		font-size: rem(28px);
	}
}

h3,
.h3 {
	font-size: rem(24px);
	color: #001F8B;
	margin-bottom: rem(18px);

	@include respond-from(none, $device-sm) {
		font-size: rem(22px);
	}
}

h4,
.h4 {
	font-size: rem(18px);
	margin-bottom: rem(14px);

	&.light {
		color: #AEB4BA;
	}
}

h5,
.h5 {
	font-size: rem(16px);
}

h6,
.h6 {
	font-size: rem(14px);
}

%justify {
	text-align: justify;
}

@include respond-from($device-xl, none) {
	.container {
		max-width: $device-xl - 30px;
	}
}

.strike {
	text-decoration: line-through;
}


/* --------------------------------
 *  Navbar styles
 *  for default navbar
 -------------------------------- */

.navbar {
	background-color: #fff;
	box-shadow: 0 2px 24px rgba(#787B8B, 0.2);

	.container {
		position: relative;
		display: flex;
		flex-flow: row wrap;
	}

	.navbar-head {
		position: relative;
		width: 240px;

		.b-categories {
			display: none;
		}

		.navbar-brand {
			margin-top: 5px;
		}

		.navbar-bottom {
			margin-top: 23px;

			.nav-link {
				display: flex;
				align-items: center;
				padding: 0;
				color: #000;
				font-family: $font-m;
				margin-top: 10px;
				line-height: 20px;

				.icon {
					margin-right: 8px;
					margin-top: -2px;
				}
			}

			.dropdown-toggle::after {
				display: none;
			}
		}
	}

	.navbar-main {
		width: 100%;

		.navbar-top {
			display: flex;
			justify-content: center;
		}

		.search-form {
			margin-top: 4px;
			display: flex;
			border: 1px solid $theme-color;
			border-radius: 30px;
			overflow: hidden;
			flex: 1;
			max-width: 500px;

			.form-control {
				font-size: rem(13px);
			}

			input {
				padding: 10px 21px;
				height: 40px;
				width: 100%;
				border: 0;
			}

			button {
				width: 60px;
				height: 40px;
				background-color: $theme-color;
				color: #fff;
				border-radius: 0;
			}
		}

		.navbar-bottom {
			display: flex;
			font-family: $font-m;
			margin-top: 10px;
			padding-bottom: 10px;
			overflow: auto;

			.nav-popup {
				position: relative;
				display: none;
			}

			.brands-popup {
				position: absolute;
				left: 0;
				width: 620px;
				background: #fff;
				z-index: 895;
				top: 44px;
				box-shadow: 0 2px 6px #0000003d;
			}

			.navbar-nav {
				display: flex;
				flex-flow: row;
				margin-left: 0;
			}

			.navbar-toggler {
				margin-left: auto;
				border-color: #000;
				height: 33px;
			}

			.dropdown-menu {
				font-size: rem(13px);
			}

			.nav-item {
				flex: none;
				margin: 0 5px;
			}

			.nav-link {
				font-size: rem(13px);
				letter-spacing: rem(0.54px);
				color: #000;
				padding-left: rem(4px);
				padding-right: rem(4px);

				&.disabled {
					color: #888;
				}
			}

			.nav-permalink {
				color: #CB1E2C;
			}
		}
	}

	.navbar-user {
		.navbar-nav {
			margin-left: 0;
		}

		.navbar-top {
			display: none;
			margin-bottom: 25px;
			margin-top: -8px;

			.nav-link {
				font-size: rem(12px);
				letter-spacing: rem(0.2px);
				color: #000;

				img {
					margin-top: -2px;
					margin-right: 2px;
				}
			}
		}

		.navbar-bottom {
			position: absolute;
			top: 20px;
			right: 15px;

			p {
				color: #ABADB6;
				font-size: rem(13px);
				letter-spacing: rem(0.54px);
				margin-bottom: 0;
				display: none;

				a {
					padding: 0 2px;
					font-family: $font-m;
				}
			}

			.navbar-nav {
				flex-flow: row;
			}

			.nav-item {
				margin-right: 5px;
			}

			.nav-link {
				position: relative;
				padding: 0 5px;
				color: #ABADB6;
				font-size: rem(20px);

				.badge {
					position: absolute;
					top: -5px;
					right: -6px;
					display: flex;
					align-items: center;
					justify-content: center;
					width: 14px;
					height: 14px;
					background-color: #FFCE47;
					border-radius: 50%;
					color: #000;
					font-size: 8px;
				}
			}
		}
	}

	@include respond-from($device-sm, none) {
		.navbar-main {
			.navbar-bottom {
				overflow: visible;

				.nav-popup {
					display: block;

					&:after {
						content: '';
						position: absolute;
						left: 0;
						right: 0;
						top: 0;
						bottom: -15px;
						cursor: pointer;
					}
				}
			}
		}
	}

	@include respond-from($device-md, none) {
		.navbar-head {
			.navbar-brand {
				margin-top: 0;
			}

			.b-categories {
				display: block;
			}
		}

		.navbar-main {
			width: auto;
			flex: 2;

			.navbar-bottom {
				padding-bottom: 0;
				margin-top: 5px;

				.navbar-nav {
					width: 100%;
					max-width: 620px;
					margin: 0 auto;
				}

				.nav-item {
					flex: 1 0 auto;
					max-width: 19%;
				}
			}
		}

		.navbar-user {
			display: block;

			.navbar-bottom {
				position: static;

				p {
					display: block;
				}

				.nav-link {
					font-size: rem(16px);
				}
			}

			.navbar-top,
			.navbar-bottom {
				display: flex;
				justify-content: flex-end;
				align-items: baseline;
			}
		}

	}

	@include respond-from($device-lg, none) {
		.navbar-main {
			.navbar-bottom {
				.nav-item {
					max-width: none;
				}
			}
		}
	}
}

/* --------------------------------
 *  Mobile navbar
 *  for mobile only navbar at bottom
 -------------------------------- */
.mob-nav {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	height: 59px;
	background-color: #fff;
	z-index: 980;

	&__holder {
		display: flex;
		height: 100%;
		align-items: center;
	}

	&__item {
		display: flex;
		flex-flow: column;
		align-items: center;
		justify-content: center;
		height: 100%;
		flex: 1;
		padding: 5px;
		font-size: rem(12px);
		font-family: $font-m;
		color: #615E6D;
		letter-spacing: 0.5px;
		text-align: center;

		.icon {
			display: block;
			width: 20px;
			height: 20px;
			margin-bottom: 5px;
		}

		&.active {
			font-family: $font-b;
			color: #1A46BB;
		}
	}

	@include respond-from($device-md, none) {
		display: none;
	}
}

.main {
	margin-top: 6px;
}

/* --------------------------------
 *  Footer styles
 *  for styling footer
 -------------------------------- */
footer {
	.footer-top {
		background-color: #D2202E;
		color: #fff;
		padding: 30px;

		.col {
			display: flex;
			align-items: center;
			justify-content: center;
			min-width: 200px;
			margin-bottom: 10px;

			&:last-child {
				margin-bottom: 0;
			}
		}

		.icon {
			display: inline-flex;
			align-items: center;
			height: 36px;
			margin-right: 8px;
		}

		@include respond-from($device-sm, none) {
			.col {
				min-width: initial;
				margin-bottom: 0;
			}
		}
	}

	.footer-bottom {
		padding: 30px 0px;

		.sitemap {
			flex-flow: row wrap;

			.col {
				flex: 1;
				min-width: 150px;

				&:last-of-type {
					min-width: 300px;
				}
			}

		}

		.social {
			align-items: flex-end;
			flex-flow: column-reverse;

			.col {
				flex: 5;

				&:last-of-type {
					flex: 1;
				}
			}

			@include respond-from($device-sm, none) {
				flex-flow: row;

				.col {
					&:last-of-type {
						min-width: 371px;
					}
				}
			}
		}

		.menu {
			color: #494747;
			font-size: rem(13px);
			letter-spacing: rem(0.54px);
			margin-bottom: 25px;

			h5,
			h6 {
				color: #000;
			}

			ul {
				list-style: none;
				margin-left: 0;

				li {
					margin-bottom: 5px;
				}
			}

			a {
				color: inherit;
			}
		}


		.connect {
			margin-bottom: 25px;

			.connect-list {
				display: flex;
				list-style: none;
				margin-left: 0;

				li {
					margin-right: 10px;
				}
			}

			@include respond-from($device-sm, none) {
				margin-bottom: 0;
			}
		}

		.payment {

			&-list {
				display: flex;
				flex-flow: row wrap;
				align-items: center;
				margin-left: 0;
				margin-bottom: 0;
				list-style: none;

				>li {
					margin-right: 10px;
					margin-bottom: 10px;
				}
			}

			@include respond-from($device-sm, none) {
				display: flex;
				flex-flow: row wrap;
				align-items: center;

				h5 {
					margin-right: 20px;
				}

			}
		}
	}

	.copyright {
		background-color: #fff;
		font-size: rem(14px);
		color: #929298;
		letter-spacing: rem(0.58px);
		padding: 15px 0;

		p {
			margin-bottom: 0;
		}

		a {
			color: inherit;
		}
	}

	@include respond-from($device-sm, none) {
		.footer-bottom {
			padding: 50px;

			.sitemap {
				.col {
					&:last-of-type {
						min-width: 371px;
					}
				}
			}

			.payment {
				margin-bottom: 0;
			}
		}
	}
}


.whtsapp-chat{
    position: fixed;
    z-index: 10;
    bottom: 100px;
    right: 20px;
    width: 60px;
    height: 60px;

    img{
        width: 60px;
    }
}
