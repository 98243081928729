/* --------------------------------
 *  Layouts
 *  for styling layout
 -------------------------------- */
.l-land {
    $l: ".l-land";
    display: flex;
    flex-flow: row wrap;
    margin-bottom: 30px;

    .breadcrumb {
        margin: 0 0 10px;
        padding: 10px 0;

        >li {
            padding-right: 15px;
        }

        a {
            color: #454545;
        }

        .active,
        .active a {
            color: #1a46bb;
        }
    }

    #{$l}__close {
        display: none;
    }

    #{$l}__banner {
        position: relative;
        border-radius: 5px;
        overflow: hidden;

        h1,
        h2,
        h3 {
            color: #fff;
        }

        &-title {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
        }

        img {
            width: 100%;
        }
    }

    #{$l}__header {
        width: 100%;
        border-bottom: 1px solid #e8e7eb;
        margin-bottom: 12px;

        h1 {
            text-align: center;
            color: #000;
            letter-spacing: rem(1px);

            span {
                color: #8f9094;
                font-size: rem(12px);
                letter-spacing: rem(0.5px);
            }
        }

        .select-block {
            padding: 5px;
            background-color: rgba(#b3bac4, 0.2);
            border-radius: 3px;

            select {
                border: 0;
                background: transparent;
            }
        }

        .prod-action {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            margin-bottom: 10px;

            &__item {
                i {
                    margin-right: 6px;
                }

                img {
                    max-height: 20px;
                }

                a {
                    color: #000;
                }

                .btn {
                    font-size: rem(12px);
                    padding: 7px 8px;
                }

                .btn-primary,
                .btn-secondary {
                    color: #fff;
                }

                .icon {
                    display: inline-block;
                    width: 18px;
                }

                margin-right: 24px;

                &:last-of-type {
                    margin-right: 0;
                }
            }

            @include respond-from(none, $device-sm) {
                &__sort {
                    display: none;
                }
            }
        }
    }

    #{$l}__aside {
        width: 100%;
        order: 3;
        margin-bottom: 30px;

        @include respond-from(none, $device-sm) {
            display: none;
            position: fixed;
            left: 20px;
            right: 20px;
            width: auto;
            top: 20px;
            bottom: 20px;
            background: #fff;
            z-index: 888;

            &.is-open {
                display: block;
            }

            &:after {
                content: "";
                position: fixed;
                z-index: -1;
                background: rgb(255, 255, 255);
                left: -20px;
                right: -20px;
                top: -20px;
                bottom: -20px;
            }
        }

        @include respond-from($device-lg, none) {
            .b-categories {
                height: 461.7px;

                &__list {
                    ul {
                        a {
                            padding: 4.5px;
                        }
                    }
                }
            }
        }
    }

    #{$l}__main {
        flex: 1;
        width: 100%;

        .tns-outer {
            position: relative;

            >button,
            .tns-controls {
                display: none;
            }

            .tns-nav {
                position: absolute;
                bottom: 10px;
                z-index: 99;
                display: flex;
                justify-content: center;
                left: 0;
                right: 0;

                button {
                    background-color: rgba(#fff, 0.6);
                    padding: 0;
                    border: 0;
                    border-radius: 3px;
                    width: 6px;
                    height: 6px;
                    display: inline-block;
                    margin: 0 5px 10px;
                    transition: all 0.2s ease-in;
                    outline: none;

                    &.tns-nav-active {
                        background-color: #fff;
                        width: 12px;
                    }
                }
            }
        }

        .home-slider {
            margin-bottom: 30px;
            border-radius: 5px;
            overflow: hidden;

            img {
                width: 100%;
            }
        }

        .prod-carousel {
            margin-bottom: 30px;

            .swiper {
                padding-bottom: 24px;
            }

            .swiper-pagination {
                bottom: 2px;
            }

            .swiper-pagination-bullet {
                width: 6px;
                height: 6px;
                border-radius: 6px;
                transition: width 0.1s ease-in;

                &.swiper-pagination-bullet-active {
                    width: 12px;
                }
            }

            .swiper-slide {
                // max-width: rem(110px);
            }

            .item {
                position: relative;
                width: 100%;
                max-width: 200px;
                // max-height: 200px;
                // padding-top: 100%;
                background-size: cover;
                background-position: center center;
                background-color: #fff;
                border-radius: 50%;
                overflow: hidden;
                margin: 0 auto;

                &:after {
                    content: "";
                    display: block;
                    width: 100%;
                    padding-top: 100%;
                }
            }

            .item-name {
                text-align: center;
            }

            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
    }

    #{$l}__paging {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        padding-top: 15px;
        border-top: 1px solid #d8d8d8;

        &-col {
            flex: 1;
            max-width: 100%;
        }

        .pagination {
            margin: 0;
        }
    }

    #{$l}__filter {
        display: flex;
        flex-flow: row wrap;
        align-items: center;

        h4,
        .chip {
            margin-right: 5px;
            margin-bottom: 10px;
        }

        h4 {
            font-size: rem(12px);
            margin-right: 10px;
            line-height: 24px;
        }
    }

    @include respond-from(none, $device-md) {
        #{$l}__paging {
            flex-flow: column-reverse wrap;

            &-col {
                display: flex;
                justify-content: center;
            }

            .pagination {
                display: flex;
                flex-flow: row wrap;
                justify-content: center;
                margin-bottom: 10px;
            }
        }
    }

    @include respond-from(none, $device-sm) {
        #{$l}__close {
            cursor: pointer;
            display: block;
            position: absolute;
            right: 0px;
            padding: 3px 10px;
            top: -10px;
        }
    }

    @include respond-from($device-sm, none) {
        #{$l}__aside {
            width: 185px;
            order: initial;
            margin-right: 15px;
        }

        #{$l}__main {
            width: calc(100% - 200px);
        }
    }

    @include respond-from($device-md, none) {
        #{$l}__aside {
            width: 220px;
        }

        #{$l}__main {
            width: calc(100% - 235px);
        }
    }

    &--home {
        @include respond-from($device-sm, none) {
            #{$l}__aside {
                margin-right: 6px;
            }
        }
    }
}

.l-card-list {
    $l: ".l-card-list";
    margin-bottom: 30px;

    #{$l}__header {
        display: flex;
        align-items: flex-end;
        margin-bottom: 10px;

        h3 {
            margin-bottom: 0;
        }

        .more-link {
            margin-left: auto;
        }
    }

    #{$l}__grid {
        display: flex;
        flex-flow: row wrap;
        margin: 0 -9px;

        #{$l}__col {
            width: 50%;
            padding: 0 9px;
            // margin-bottom: 20px;
        }

        .b-prod-card__action--center {
            // margin-left: auto;
            margin-right: auto;
        }
    }

    #{$l}__list {
        display: flex;
        flex-flow: column;

        #{$l}__col {
            // padding: 0 10px;
            margin-bottom: 12px;
        }

        .b-prod-card {
            $b: ".b-prod-card";
            display: flex;

            #{$b}__thumb {
                flex: 1;
                max-width: 250px;
            }

            #{$b}__main {
                flex: 2;
            }

            #{$b}__info {
                display: flex;
                flex-flow: column;
            }

            #{$b}__title {
                display: none;
            }

            #{$b}__big-title {
                display: block;
                flex: 1;
                margin-right: 10px;
            }

            #{$b}__image {
                padding-top: 45%;
            }

            #{$b}__price {
                padding-bottom: 0;
                border: 0;
                flex-flow: column-reverse;

                &-before {
                    margin-right: 0;
                }
            }
        }
    }

    @include respond-from($device-sm, none) {
        #{$l}__grid {
            flex-flow: row wrap;

            .b-prod-card {
                min-width: 166px;
            }

            #{$l}__col {
                flex: 1 0 33.33%;
                width: auto;
            }
        }

        #{$l}__list {
            .b-prod-card {
                $b: ".b-prod-card";

                #{$b}__info {
                    flex-flow: row;
                }

                #{$b}__main {
                    padding: 25px 15px;
                }

                #{$b}__price {
                    &:after {
                        content: "";
                        position: absolute;
                        right: 150px;
                        top: 0;
                        bottom: 0;
                        width: 1px;
                        background-color: #f2f2f2;
                    }
                }

                #{$b}__stock,
                #{$b}__action,
                #{$b}__price-before,
                #{$b}__price-val {
                    width: 140px;
                    padding-left: 25px;
                }
            }
        }
    }

    @include respond-from($device-lg, none) {
        #{$l}__grid {
            #{$l}__col {
                flex: 1 0 25%;
            }
        }

        #{$l}__list {
            .b-prod-card {
                $b: ".b-prod-card";

                #{$b}__price {
                    &:after {
                        right: 200px;
                    }
                }

                #{$b}__stock,
                #{$b}__action,
                #{$b}__price-before,
                #{$b}__price-val {
                    width: 190px;
                    padding-left: 30px;
                }
            }
        }
    }
}

.l-brands {
    $l: ".l-brands";
    margin-bottom: 30px;

    &__list {
        display: -ms-grid;
        display: grid;
        grid-gap: rem(22px);
        -ms-grid-columns: repeat(auto-fit, minmax(130px, 1fr));
        -webkit-grid-columns: repeat(auto-fit, minmax(130px, 1fr));
        grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
    }

    &__item {
        height: 80px;
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 3px;
        max-width: 200px;
        padding-left: 5px;
        padding-right: 5px;

        img {
            max-width: 100%;
        }
    }

    &--sm {
        #{$l}__item {
            max-width: 150px;
        }

        #{$l}__list {
            -ms-grid-columns: repeat(auto-fit, minmax(120px, 1fr));
            -webkit-grid-columns: repeat(auto-fit, minmax(120px, 1fr));
            grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
        }
    }
}

.l-product {
    $l: ".l-product";

    #{$l}__main {
        display: flex;
        flex-flow: column;
        justify-content: stretch;
        margin-left: -10px;
        margin-right: -10px;
        margin-bottom: 30px;

        .l-product__box {
            margin: 0 10px;
            padding: 15px;
            background-color: #fff;
            border-radius: 5px;
        }
    }

    #{$l}__slider {
        padding: 0;
        background: none;
        margin-bottom: 10px;

        .tns-controls {
            display: none;
        }

        .l-product__slider-wrap {
            padding: 40px 30px;
            overflow: hidden;
            margin-bottom: 10px;
        }

        .product-image {
            padding-top: 64%;
            background-size: cover;
            background-position: center center;
        }
    }

    #{$l}__logo {
        margin-bottom: 10px;
    }

    #{$l}__thumbs {
        margin: 0 10px;

        .thumb-slider {
            margin-bottom: 10px;

            .p-1 {
                background-color: #fff;
                border-radius: 4px;
            }

            .thumb-image {
                padding-top: 64%;
                background-size: contain;
                background-position: center center;
                background-repeat: no-repeat;
            }
        }
    }

    #{$l}__tags {
        display: flex;
        flex-flow: row wrap;
        margin: 0 10px;
        padding: 10px;
        background-color: #fff;
        border-radius: 5px;

        .tag-list {
            flex: auto;
            font-size: rem(12px);
            color: #000;
            padding: 5px 0;
            margin-right: 15px;
        }
    }

    #{$l}__share {
        flex: auto;
        padding: 10px;

        .downloads {
            display: flex;
            flex-flow: column;
            align-items: center;
            margin-bottom: 10px;

            .icon {
                margin-bottom: 5px;
            }

            .link-text {
                font-size: rem(13px);
                letter-spacing: rem(0.3px);
                color: #333333;
                font-weight: 600;
            }

            a {
                text-decoration: none;
            }
        }

        .share {
            .text-label {
                color: #515151;
                font-size: rem(12px);
                letter-spacing: rem(0.24px);
                margin-bottom: 5px;
            }

            ul {
                list-style: none;
                display: flex;
                margin-left: 0;
                padding-left: 0;

                li {
                    margin-right: 5px;

                    &:last-of-type {
                        margin-right: 0;
                    }
                }
            }
        }
    }

    #{$l}__info {
        &.l-product__box {
            padding: 30px;
        }

        .field-label {
            color: #8f9094;
            font-size: rem(12px);
        }
    }

    #{$l}__meta {
        color: #1d1d1f;
        font-size: rem(12px);
        align-items: flex-end;

        >div {
            margin-bottom: 5px;
        }
    }

    #{$l}__cat {
        color: #8f9094;
        font-size: rem(12px);
    }

    #{$l}__title {
        color: $theme-color;
        font-size: rem(20px);
        letter-spacing: rem(0.83px);
    }

    #{$l}__head {
        display: flex;
        flex-flow: column-reverse;
        margin-left: -15px;
        margin-right: -15px;
        margin-bottom: 15px;

        &-col {
            padding-left: 15px;
            padding-right: 15px;
        }
    }

    #{$l}__price {
        align-items: flex-end;
        margin-bottom: 20px;

        &-now {
            font-size: rem(16px);
            letter-spacing: rem(0.67px);
        }

        &-before {
            font-size: rem(12px);
            color: #8f9094;
            text-decoration: line-through;
        }

        &-discount {
            font-size: rem(13px);
            color: #d2202e;
        }
    }

    #{$l}__field {
        display: flex;
        font-size: rem(12px);
        margin-bottom: 20px;

        .field-label {
            flex: 1;
            max-width: 60px;
            margin-right: 10px;
        }
    }

    #{$l}__color {
        &-name {
            color: #1d1d1f;
        }
    }

    #{$l}__rate {
        position: relative;
        padding-left: 5px;
        padding-right: 5px;

        &-slider {
            margin-bottom: 25px;
        }

        &-controls {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;

            .fa-angle-left,
            .fa-angle-right {
                position: absolute;
                display: flex;
                align-items: center;
                justify-content: center;
                top: 50%;
                border-radius: 50%;
                width: 20px;
                height: 20px;
                margin-top: -10px;
                cursor: pointer;
                color: $theme-color;
                background-color: #fff;
                box-shadow: 0 1px 6px rgba(#3b3963, 0.18);
                z-index: 99;
            }

            .fa-angle-left {
                left: 0px;
            }

            .fa-angle-right {
                right: 0px;
            }
        }

        .price {
            font-size: rem(14px);
            color: #000;
            letter-spacing: rem(0.58px);
        }

        .date {
            font-size: rem(12px);
            letter-spacing: rem(0.5px);
            color: #8f9094;
        }

        img {
            margin-bottom: 6px;
            max-width: 100%;
        }

        &-item {
            padding: 10px;
            text-align: center;
            border: 1px solid rgba(#e8e7eb, 0.46);
            border-radius: 2px;
        }
    }

    #{$l}__plan {
        background-color: #eff2fc;
        padding: 10px 15px;
        border-radius: 3px;
        margin-bottom: 20px;

        .icon {
            margin-right: 10px;
        }

        span {
            margin-right: 10px;
        }
    }

    #{$l}__actions {
        display: flex;
        align-items: center;

        .btn {
            padding: 12px 15px;
            font-size: rem(13px);
            margin-right: 20px;
            min-width: 100px;
        }

        .checkbox-round {
            display: none;
            color: #8f9094;

            .bullet {
                top: 3px;
            }
        }
    }

    #{$l}__description {
        margin-bottom: 50px;

        table {
            width: 100%;
            margin-bottom: 1rem;
            color: #212529;
        }

        table td,
        table th {
            padding: 0.75rem;
            vertical-align: top;
            border-top: 1px solid #dee2e6;
        }

        table thead th {
            vertical-align: bottom;
            border-bottom: 2px solid #dee2e6;
        }

        table tbody+tbody {
            border-top: 2px solid #dee2e6;
        }

        table {
            border: 1px solid #dee2e6;
        }

        table td,
        table th {
            border: 1px solid #dee2e6;
        }

        table thead td,
        table thead th {
            border-bottom-width: 2px;
        }
    }

    #{$l}__prod-slider {
        margin-top: 40px;
    }

    .image-list {
        margin-top: 50px;
        text-align: center;

        img {
            max-width: 100%;
            margin: 0 auto 40px;
        }
    }

    .prod-slider {
        position: relative;

        &__controls {
            position: absolute;
            top: 35%;
            left: 0;
            right: 0;

            .fa-angle-left,
            .fa-angle-right {
                position: absolute;
                display: flex;
                align-items: center;
                justify-content: center;
                top: 50%;
                border-radius: 50%;
                width: 34px;
                height: 34px;
                margin-top: -16px;
                cursor: pointer;
                color: $theme-color;
                background-color: #fff;
                box-shadow: 0 1px 6px rgba(#3b3963, 0.18);
                z-index: 99;
            }

            .fa-angle-left {
                left: -10px;
            }

            .fa-angle-right {
                right: -10px;
            }
        }
    }

    @include respond-from($device-sm, none) {
        #{$l}__main {
            flex-flow: row;
        }

        #{$l}__slider {
            margin-bottom: 0;
            width: 38%;
        }

        #{$l}__logo {
            margin-bottom: 0;
        }

        #{$l}__info {
            width: 63.3334%;
        }

        #{$l}__actions {
            .btn {
                min-width: 120px;
            }

            .checkbox-round {
                display: block;
            }
        }

        #{$l}__head {
            flex-flow: row;
            align-items: flex-end;

            &-col {
                &:last-of-type {
                    margin-left: auto;
                }
            }
        }
    }

    @include respond-from($device-md, none) {
        #{$l}__actions {
            .btn {
                min-width: 160px;
            }
        }
    }
}

.l-compare {
    $l: ".l-compare";
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    right: 0;
    bottom: 0;
    height: 88px;
    background-color: #fff;
    box-shadow: 0 -23px 21px rgba(#000, 0.08);
    z-index: 960;

    #{$l}__container {
        padding: 0 50px;
    }

    #{$l}__title {
        color: #000;
        font-size: rem(16px);
        letter-spacing: rem(0.67px);

        span {
            font-size: rem(13px);
            color: #7b7c7f;
        }
    }

    #{$l}__action {
        display: flex;
        justify-content: flex-end;

        .btn {
            margin-right: 10px;

            &:last-of-type {
                margin-right: 0;
            }
        }
    }

    #{$l}__prods {
        display: flex;
    }

    #{$l}__thumb {
        position: relative;
        width: 100px;
        height: 70px;
        margin-right: 10px;
        border: 1px solid #d7dade;
        border-radius: 3px;

        &:last-of-type {
            margin-right: 0;
        }
    }

    #{$l}__image,
    #{$l}__blank {
        position: absolute;
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
    }

    #{$l}__image {
        left: 15px;
        right: 15px;
        top: 12px;
        bottom: 5px;
    }

    #{$l}__blank {
        width: 40px;
        height: 40px;
        left: 50%;
        top: 50%;
        margin-top: -20px;
        margin-left: -20px;
        background-image: url("../images/icon-comp-plus.png");
    }

    #{$l}__close {
        position: absolute;
        right: 3px;
        top: 3px;
        width: 12px;
        height: 12px;
        background: url("../images/icon-comp-close.png") center center no-repeat;
        background-size: contain;
    }

    @include respond-from($device-sm, $device-md) {
        #{$l}__container {
            min-width: 960px;
        }
    }
}

.l-prod-comp {
    $l: ".l-prod-comp";

    h4 {
        color: rgba(#000, 0.4);
        font-size: rem(18px);
        margin-bottom: 0;
        text-transform: uppercase;
    }

    .table-striped {
        padding-left: 15px;
        padding-right: 15px;
        margin-bottom: 0;

        &:last-of-type {
            margin-bottom: 30px;
        }

        .tr {
            &:nth-child(even) {
                background-color: #eff2fc;
            }
        }

        .td {
            padding: 15px;
            font-size: rem(14px);
            color: #454545;
            border-right: 1px solid #e8e7eb;

            &:first-of-type {
                max-width: 200px;
                font-weight: 600;
                color: #000;
                font-size: rem(16px);
            }

            &:last-of-type {
                border-right: 0;
            }
        }

        @include respond-from($device-lg, none) {
            .td {
                padding: 25px 30px;
            }
        }
    }

    .b-prod-card {
        margin-bottom: 0;
    }

    .b-prod-card__main {
        padding: 0;
    }

    .table {
        background-color: #fff;

        tbody {
            >tr {
                padding-left: 30px;
                padding-right: 30px;

                >td,
                >th {
                    border-right: 1px solid #e8e7eb;
                }

                >td {
                    &:last-of-type {
                        border: 0;
                    }
                }
            }
        }
    }

    #{$l}__header {
        display: flex;
        justify-content: space-between;
        padding: 30px;
        background: #fff;
    }

    #{$l}__close {
        position: absolute;
        right: 15px;
        top: 15px;
        color: $theme-color;
    }
}

.l-alert {
    $l: ".l-alert";
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 990;
    display: none;

    &.is-open {
        display: block;
    }

    &__overlay {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: rgba(#000, 0.5);
        z-index: 5;
    }

    &__body {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__icon {
        img {
            margin-bottom: 15px;
        }
    }

    &__content {
        padding: 15px;
    }

    &__message {

        max-height: calc(100vh - 160px);
        padding: 15px;
        overflow: auto;
    }

    &__container {
        position: relative;
        background-color: #fff;
        border-radius: 3px;
        text-align: center;
        width: 440px;
        max-width: 80%;
        z-index: 10;

        p {
            text-align: center;
            font-size: rem(14px);
            color: #000;
            letter-spacing: rem(0.58px);
            font-weight: 600;
        }

        img {
            max-width: 100%;
            max-height: calc(100vh - 120px);
        }
    }

    &__close {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        height: rem(50px);
        width: rem(50px);
        background: #fff;
        right: -50px;
        top: -50px;
        border-radius: 50%;
        cursor: pointer;

        &:after,
        &:before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            width: rem(2.8px);
            height: 20px;
            background-color: $theme-color;
        }

        &:after {
            transform: translate(-50%, -50%) rotate(45deg);
        }

        &:before {
            transform: translate(-50%, -50%) rotate(-45deg);
        }
    }

    &--banner {
        #{$l}__container {
            width: auto;
            max-width: 70vw;
        }
    }
}

.l-dialog {
    $l: ".l-dialog";

    position: relative;
    display: none;
    z-index: 920;

    &__wrap {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &--is-open {
        display: block;
    }

    .c-accordion {
        .c-accordion__head {
            padding: 10px;
            background-color: #eff2fc;
        }

        .c-accordion__body {
            padding: 10px;
            background-color: #fff;
        }

        .c-accordion__item {
            padding: 0;
            margin-bottom: 6px;

            &:last-of-type {
                // padding-bottom: 10px;
            }
        }
    }

    #{$l}__overlay {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: rgba(#000, 0.34);
        z-index: -1;
    }

    #{$l}__container {
        position: relative;
        max-width: 700px;
        width: 80%;
        max-height: 100%;
        max-height: 80%;
        margin-top: 100px;
        margin-bottom: 100px;
        background-color: #fff;
        padding: 15px;
        overflow: auto;
    }

    #{$l}__close {
        position: absolute;
        right: 15px;
        top: 15px;
        cursor: pointer;
        line-height: 1;
        font-size: rem(20px);
        color: $theme-color;
    }

    #{$l}__header {
        text-align: center;
        font-size: rem(16px);
        font-weight: 600;
        margin-bottom: 20px;
    }

    &--login {
        $c: ".c-tabs";

        #{$l}__header {
            margin-bottom: 0;
        }

        #{$l}__container {
            padding: 0;
            max-width: 400px;
        }

        #{$c}__header {
            margin-bottom: 0;

            >ul {
                background-color: #e8e7eb;

                >li {
                    flex: 1;
                }
            }
        }

        #{$c}__body {
            .text-link {
                display: inline-block;
                color: #7b7c7f;
                margin-bottom: 10px;
            }

            .btn-primary {
                font-size: rem(13px);
                text-transform: uppercase;
            }

            >ul {
                margin-bottom: 0;

                >li {
                    padding: 20px 15px;
                }
            }

            .row {
                margin-left: -7.5px;
                margin-right: -7.5px;
            }

            .col {
                padding-left: 7.5px;
                padding-right: 7.5px;
            }
        }

        @include respond-from($device-sm, none) {
            #{$c}__body {
                >ul {
                    >li {
                        padding: 30px 25px;
                    }
                }
            }
        }
    }

    &--pw-reset {
        $c: ".c-tabs";

        #{$l}__container {
            padding: 0;
            max-width: 400px;
        }

        #{$l}__header {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            background-color: #e8e7eb;
            padding: 10px 15px;
            height: 50px;
            margin-bottom: 0;
        }

        #{$l}__body {
            text-align: center;
            padding: 25px;
        }
    }
}

.l-my-cart {
    $l: ".l-my-cart";
    margin-bottom: 30px;

    #{$l}__summary,
    #{$l}__order {
        background-color: #fff;
        border-radius: 3px;
        padding: 20px;

        .table-responsive {
            margin-left: rem(-10px);
            margin-right: rem(-10px);
        }

        .table {
            th {
                font-size: rem(12px);
                color: #8f9094;
                font-family: $font-m;
            }

            th,
            td {
                padding: rem(10px);
            }
        }
    }

    #{$l}__order {
        h4 {
            span {
                color: #7a7986;
                font-size: rem(16px);
                letter-spacing: rem(0.67px);
            }
        }

        table {
            margin-bottom: 0;

            tbody {
                tr {
                    border-bottom: 1px solid rgba(#e8e7eb, 0.7);
                    margin-bottom: 10px;

                    &:last-of-type {
                        border-bottom: 0;
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    #{$l}__product {
        display: flex;
    }

    #{$l}__thumb {
        max-width: 113px;
        min-width: 50px;
        flex: 1;
        margin-right: 10px;
    }

    #{$l}__info {
        flex: 3;
        min-width: 130px;
    }

    #{$l}__cat {
        font-size: rem(12px);
        color: #8f9094;
    }

    #{$l}__title {
        font-size: rem(14px);
        color: $theme-color;
        font-family: $font-m;
        margin-bottom: 9px;
        line-height: 1.4;
    }

    #{$l}__remove {
        cursor: pointer !important;
        color: #7a7986;
        font-size: rem(13px);

        .icon {
            position: relative;
            top: -2px;
            margin-right: 3px;
        }
    }

    #{$l}__image {
        padding-top: 50%;
        height: 100%;
        background-position: center center;
        background-size: contain;
        background-repeat: no-repeat;
    }

    #{$l}__qty {
        min-width: 28px;
        display: flex;

        span {
            display: inline-block;
            width: 20px;
            height: 21px;
            border-radius: 2px;
            background-color: $theme-color;
            color: #fff;
            text-align: center;
            cursor: pointer;
        }

        input {
            max-width: 24px;
            height: 21px;
            line-height: 1;
            margin: 0 4px;
            text-align: right;
            font-size: rem(13px);
            font-family: $font-m;
        }
    }

    #{$l}__price {
        margin-top: 4px;
        font-size: rem(14px);
        color: #000;
        font-family: $font-m;
        text-align: right;
    }

    #{$l}__continue {
        text-align: right;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    #{$l}__summary {
        ul {
            font-size: rem(14px);
            color: #7a7986;
            font-family: $font-m;
            list-style: none;
            margin-left: 0;
            padding-bottom: 20px;
            border-bottom: 1px solid #e8e7eb;
            margin-bottom: 20px;

            >li {
                display: flex;

                &:nth-child(1) {
                    margin-bottom: 7px;
                }
            }
        }

        label {
            font-size: rem(13px);
            color: #454545;
            letter-spacing: rem(0.5px);
            font-family: $font-m;
        }
    }

    #{$l}__total {
        display: flex;
    }
}

.l-checkout {
    $l: ".l-checkout";

    border-radius: 5px;
    background-color: #fff;
    margin-bottom: 30px;

    #{$l}__container {
        margin: 0 auto;
        padding-left: 15px;
        padding-right: 15px;
    }

    #{$l}__breadcrumb {
        background-color: #f2f4f7;

        a {
            color: $theme-color;
        }
    }

    #{$l}__steps {
        display: flex;
        flex-flow: row-reverse;
        justify-content: space-around;
        max-width: 780px;
        margin: 50px auto;
    }

    #{$l}__step-sep {
        flex: 1;

        &:after {
            content: "";
            height: 4px;
            display: block;
            background-color: #dee2e8;
            margin: 0 10px;
            top: 50%;
            position: relative;
        }
    }

    #{$l}__step {
        a {
            color: #7a7986;
            text-decoration: none;
        }

        .count {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 28px;
            height: 28px;
            background-color: #dee2e8;
            margin-right: 12px;
            border-radius: 50%;
            color: #7a7986;
            font-style: normal;
        }

        &.active {
            a {
                color: #28a951;
            }

            .count {
                color: #fff;
                background-color: #28a951;
            }

            +#{$l}__step-sep {
                &:after {
                    background-color: #28a951;
                }
            }
        }

        @media (max-width: $device-xs) {
            a {
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
            }

            .count {
                margin-right: 0;
            }
        }
    }

    #{$l}__fblock {
        max-width: 350px;
        margin: 0 auto 30px;
    }

    #{$l}__fblock2 {
        max-width: 960px;
        margin: 0 auto 30px;

        &.flex-block {
            display: flex;
            align-items: flex-start;
        }

        .label-group {
            font-size: rem(12px);
            color: #1d1d1f;
            font-family: $font-m;
        }

        form {
            flex: 1;
        }

        .order-summary-block {
            min-width: 360px;
            margin-left: 50px;
            border: 1px solid #D7DADE;
            padding: 20px;

            table {
                width: 100%;

                .shipping-tr {
                    td {
                        padding-bottom: 20px;
                    }
                }

                .total-tr {
                    td {
                        font-family: $font-m;
                        font-size: 18px;
                        color: #000;
                        padding-top: 20px;
                        border-top: 1px solid #E8E7EB;
                    }
                }

                td {
                    font-family: $font-m;
                    font-weight: 400;
                    letter-spacing: .4px;
                    color: #7A7986;
                    padding: 2px 0;

                    &:last-child {
                        text-align: right;
                    }

                }
            }
        }

        @media (max-width: $device-md) {
            flex-direction: column;

            form {
                width: 100%;
            }

            .order-summary-block {
                width: 100%;
                min-width: auto;
                margin-left: 0;
            }
        }

    }

    #{$l}__subtitle {
        font-size: rem(13px);
        color: #7b7c7f;
        font-family: $font-m;
    }

    #{$l}__sub {
        font-family: $font-p;
        letter-spacing: .6px;
    }

    .c-tabs .c-tabs__body>ul>li {
        padding: 0;
        overflow: hidden;
    }

    .c-accordion__head {
        background: #fff;
    }

    form {
        padding-bottom: 30px;
    }

    .text-link {
        display: inline-block;
        color: #7b7c7f;
        margin-bottom: 10px;
    }
}

.l-account {
    $l: ".l-account";

    .breadcrumb {
        // margin-top: -10px;
    }

    form {
        .btn-lg {
            font-size: rem(13px);
            margin-top: 10px;
            padding: 12px 25px;
            min-width: 260px;
        }

        .group-label {
            font-size: rem(14px);
            margin-bottom: 12px;
            font-family: $font-m;
            letter-spacing: rem(0.58px);
        }

        fieldset {
            margin-bottom: 20px;

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }

    #{$l}__aside {
        margin-bottom: 30px;
    }

    #{$l}__content {
        h3 {
            color: #000;
            font-family: $font-m;

            span {
                color: #8f9094;
                font-size: rem(12px);
            }
        }
    }

    #{$l}__menu {
        position: -webkit-sticky;
        position: sticky;
        top: 30px;
        background-color: #fff;
        border-radius: 5px;
        padding: 20px 15px;

        &-item {
            position: relative;
            padding-left: 25px;
            margin-bottom: 14px;

            a {
                font-family: $font-m;
                color: #454545;
            }

            .icon {
                position: absolute;
                display: inline-block;
                left: 0;
                top: -2px;
                width: 15px;
                margin-right: 10px;
            }

            &.active {
                a {
                    color: #000;
                    // font-weight: 600;
                }
            }

            &:last-of-type {
                margin-bottom: 0;
            }
        }

        &-sep {
            border-bottom: 1px solid #e8e7eb;
            margin: 10px -15px;
        }
    }

    #{$l}__orders {
        margin-bottom: 8px;
    }

    #{$l}__profile {
        background: #fff;
        padding: 20px;
        color: #454545;
        font-size: rem(13px);
        margin-bottom: 40px;

        >.row {
            >div {
                margin-bottom: 15px;
            }
        }

        h5 {
            color: #000;
            margin-bottom: 5px;
            font-size: rem(13px);
        }

        .head {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            margin-bottom: 10px;
        }

        .name {
            color: $theme-color;
            font-family: $font-m;
            font-size: rem(16px);
        }

        .icon-para {
            position: relative;
            padding-left: 26px;
            margin-bottom: 10px;

            a {
                color: #454545;
            }

            .icon {
                position: absolute;
                left: 0;
                top: -2px;
            }

            p {
                margin-bottom: 10px;

                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }
    }

    @include respond-from($device-sm, none) {
        #{$l}__main {
            display: flex;
        }

        #{$l}__aside {
            max-width: 235px;
            margin-right: 25px;
            margin-bottom: 15px;
            flex: 1;
        }

        #{$l}__content {
            flex: 3;
        }
    }
}

.l-contact {
    $l: ".l-contact";

    h3 {
        font-size: rem(24px);
        color: #000;
        font-family: $font-m;
    }

    #{$l}__top {
        display: flex;
        flex-flow: row wrap;
        margin-bottom: 30px;
    }

    #{$l}__form {
        width: 100%;

        .content {
            background-color: #fff;
            border-radius: 3px;
            padding: 30px;
        }

        margin-bottom: 30px;
    }

    #{$l}__info {
        background-color: #fff;
        border-radius: 3px;
        padding: 30px;
        width: 100%;
    }

    #{$l}__bottom {
        display: flex;
    }

    #{$l}__showrooms {
        width: 100%;

        .content {
            background-color: #fff;
            border-radius: 3px;
            padding: 30px;
        }

        .table {
            // min-width: 460px;

            th {
                font-size: rem(16px);
                padding-left: 0;
            }

            td {
                color: #454545;
            }
        }

        .table-striped tbody tr:nth-of-type(even) {
            background-color: rgba(#eff2fc, 0.24);
        }
    }

    @include respond-from($device-sm, none) {

        #{$l}__top,
        #{$l}__bottom {
            margin-bottom: 40px;
        }

        #{$l}__form,
        #{$l}__showrooms {
            width: 58%;
            margin-bottom: 0;

            .content {
                margin-right: 30px;
                height: 100%;
                padding: 30px 30px;
            }
        }

        #{$l}__info {
            flex: 1;
            width: 42%;
            padding: 30px;
        }
    }

    @include respond-from($device-md, none) {

        #{$l}__form,
        #{$l}__showrooms {
            width: 60%;
        }

        #{$l}__form {
            p {
                color: #454545;
                font-size: rem(14px);
            }

            label {
                font-size: rem(14px);
                color: #000;
                font-family: $font-m;
            }

            .form-control {
                color: #7b7c7f;
                font-size: rem(13px);
            }

            .content {
                padding: 50px 70px;
            }
        }

        #{$l}__info {
            width: 40%;
            padding: 50px;

            h4 {
                font-size: rem(17px);
                margin-bottom: 10px;
            }

            p {
                color: #454545;
                font-size: rem(15px);
                line-height: rem(19px);
            }

            &-block {
                margin-bottom: 40px;

                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }
    }
}

.l-thankyou {
    $l: ".l-thankyou";
    margin: 50px auto 50px;
    max-width: 700px;

    .table-wrap {
        margin-left: -rem(14px);
        margin-right: -rem(14px);
    }

    .strong-text {
        font-size: rem(18px);
        font-family: $font-m;
    }

    #{$l}__top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 15px;
    }

    #{$l}__seg {
        padding: 15px;
        background-color: #fff;
    }

    #{$l}__body {
        background-color: #eff2fc;
        font-size: rem(16px);
        letter-spacing: rem(0.34px);
        font-family: $font-m;
        color: #000;
        // padding: 15px 0;
    }

    #{$l}__customer {
        border: 1px solid #e8e7eb;
    }

    @include respond-from($device-sm, none) {
        .table {
            .spacer-3 {
                width: 30%;
            }
        }

        #{$l}__seg {
            padding: 25px 50px;
        }
    }
}

/* .l-categories {
	.b-categories {
		width: 50%;
		padding: 5px;
		margin-bottom: 20px;

		&__brands {
			display: none;
		}

		&__block {
			width: 100%;
		}

		&__seg {
			left: 0;
			right: 0;
		}
	}
} */

.l-quote {
    &__content {
        padding: 30px 15px;
    }

    &__request {
        background-color: #fff;
    }

    &__contact {
        background-color: $theme-color;
        color: #fff;

        h4 {
            color: #fff;
        }
    }

    &__team {
        >.row {
            margin-left: -5px;
            margin-right: -5px;

            >div {
                padding-left: 5px;
                padding-right: 5px;
            }
        }
    }

    &__card {
        background-color: #fff;
        padding: 15px;
        color: #000;
        margin-bottom: 10px;

        h5 {
            // font-size: rem(20px);
            color: #000;
            margin-bottom: 5px;
        }

        ul {
            list-style: none;
            margin: 0;
            padding-left: 0;
            color: #454545;
            font-size: rem(13px);

            strong {
                display: inline-block;
                min-width: rem(65px);
                color: #000;
            }
        }
    }

    @include respond-from($device-xs, none) {
        &__content {
            padding: 35px 25px;
            max-width: 600px;
            margin: 0 auto;
        }
    }

    @include respond-from($device-md, none) {
        display: flex;

        >div {
            flex: 1;
        }

        &__content {
            padding: 35px;
            max-width: none;
        }

        &__card {
            padding: 24px;
        }
    }

    @include respond-from($device-lg, none) {
        &__content {
            padding: 50px;
        }
    }
}

.l-img-text {
    $l: ".l-img-text";

    display: flex;
    // align-items: center;
    flex-flow: column-reverse;
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: 30px;

    >div {
        padding-left: 15px;
        padding-right: 15px;
    }

    h1,
    h2,
    h3 {
        color: #000;
    }

    >div {
        margin-bottom: rem(30px);
    }

    #{$l}__img {
        img {
            width: 100%;
        }
    }

    @include respond-from($device-sm, none) {
        flex-flow: row;
        align-items: center;

        &--swap {
            flex-flow: row-reverse;
        }

        #{$l}__text {
            flex: 5 0;
        }

        #{$l}__img {
            flex: 3 0;

            img {
                width: 100%;
            }
        }
    }
}

.l-banner-txt {
    padding: 80px 0;
    color: #fff;
    background-size: cover;
    background-position: 60% 50%;
    margin-bottom: 50px;

    h1,
    h2,
    h3 {
        color: inherit;
    }

    &__text {
        width: 80%;
    }

    @include respond-from($device-sm, none) {
        background-position: 50% 50%;

        &__text {
            width: 70%;
            max-width: 720px;
        }
    }
}

.l-faq {
    max-width: 900px;
    margin: 0 auto 50px;

    &__form {
        padding: 25px;
        background-color: #fff;
    }
}

.l-blog {
    margin-bottom: 30px;

    &__article {
        background-color: #fff;
        border-radius: 5px;
        padding: 20px;
        margin-bottom: 30px;
    }

    &__share {
        margin-bottom: 40px;
    }

    &__updates {
        margin-bottom: 40px;
    }

    &__tag {
        display: flex;
        font-size: rem(12px);
        color: #8f9094;
        margin-bottom: 7px;
    }

    &__category {
        &:before {
            margin: 0 5px 0 8px;
            content: "/";
        }
    }

    &__title {
        font-size: rem(24px);
        color: #000;
        line-height: 1.4;
    }

    &__main {
        img {
            margin-bottom: 30px;
            width: 100%;
        }
    }

    @include respond-from($device-sm, none) {
        display: flex;

        &__article {
            flex: 5;
            margin-right: 30px;
        }

        &__share {
            order: -1;
            width: 75px;
        }

        &__updates {
            flex: 3;
        }
    }

    @include respond-from($device-md, none) {
        &__article {
            padding: 30px;
            margin-right: 40px;
        }
    }
}

.l-branches {
    .l-land {
        position: absolute;
        left: 0;
        right: 0;
        z-index: 8;
    }

    #branches-map {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }

    &__map {
        position: relative;
        min-height: 300px;
    }

    &__search {
        display: flex;
        padding: 10px 30px;
        border-bottom: 1px solid #e8e7eb;
        margin-bottom: 20px;

        .icon {
            padding: 5px 0;
            text-align: right;
            height: 40px;
            display: flex;
            align-items: center;
            margin-bottom: 0;
        }

        input {
            height: 40px;
            flex: 1;
            background: none;
            padding: 5px 10px;
            margin-left: 5px;
            border: 0;
            margin-bottom: 0;
        }
    }

    &__list {
        padding: 0 30px;

        h3 {
            font-size: rem(24px);
            color: #000;
        }

        ul {
            margin-left: 0;
            list-style: none;

            >li {
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-top: 1px solid #e8e7eb;
                font-size: rem(18px);
                font-weight: bold;
                padding: 15px 0;
                cursor: pointer;

                i {
                    padding: 0 10px;
                    margin-right: 10px;
                }
            }
        }
    }

    .branch-content {
        padding: 40px;
        display: none;

        span.medium {
            display: inline-block;
            font-family: $font-m;
            margin-left: 5px;
        }

        h3,
        h4,
        h5 {
            color: #000;
        }

        .back-to {
            color: #8F9094;
            display: flex;
            align-items: center;
            margin-bottom: 20px;

            img {
                margin-right: 10px;
            }
        }

        .get-direction {
            font-family: $font-m;
            display: flex;
            align-items: center;

            img {
                max-width: 7px;
                margin-left: 10px;
            }
        }

        hr {
            margin: 20px 0;
        }

        .top {
            margin-bottom: 20px;

            p {
                margin: 0;
            }
        }

        .bottom {
            p {
                margin: 0;
            }

            a {
                font-family: $font-m;
                color: #454545;

                &.mail {
                    color: $theme-color;
                }
            }

            h5 {
                margin: 20px 0;
            }
        }
    }

    @include respond-from($device-sm, none) {
        &__main {
            display: flex;
        }

        &__list {
            ul {
                height: 395px;
                overflow: auto;
            }
        }

        &__map {
            flex: 7;
            z-index: 7;
        }

        &__aside {
            flex: 3;
            z-index: 9;
        }
    }
}

.lds-loader {
    position: fixed;
    display: none;
    align-items: center;
    justify-content: center;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: rgba(#000, 0.5);
    z-index: 9999;

    &.is-loading {
        display: flex;
    }


    .lds-ring {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;

        div {
            box-sizing: border-box;
            display: block;
            position: absolute;
            width: 64px;
            height: 64px;
            margin: 8px;
            border: 8px solid #fff;
            border-radius: 50%;
            animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            border-color: #fff transparent transparent transparent;

            &:nth-child(1) {
                animation-delay: -0.45s;
            }

            &:nth-child(2) {
                animation-delay: -0.3s;
            }

            &:nth-child(3) {
                animation-delay: -0.15s;
            }
        }
    }
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
