/* --------------------------------
 *  Blocks
 *  for common style blocks
 -------------------------------- */
.b-filters {
	$b: '.b-filters';
	padding: 8px;
	background-color: #fff;
	border-radius: 5px;

	#{$b}__head {
		display: flex;
		align-items: flex-end;
		padding: 5px 8px 10px;
		margin: 0 -8px 8px;
		font-size: rem(13px);
		color: #000;
		font-weight: 600;
		border-bottom: 1px solid #E8E7EB;

		h4 {
			margin-right: auto;
			margin-bottom: 0;
		}

		input[type=reset] {
			background: none;
			padding: 0;
			border: 0;
			color: $theme-color;
		}
	}

	.checkbox-round {
		font-size: rem(13px);
		color: #454545;

		input[type=checkbox] {
			&:checked+.bullet {
				background-color: $theme-color;
			}
		}
	}

	.bullet {
		margin-right: 5px;
	}

	&__list {
		.c-accordion__item {
			padding-left: 8px;
			padding-right: 8px;
			margin-left: -8px;
			margin-right: -8px;
			border-bottom: 1px solid #E8E7EB;

			&:last-of-type {
				border-bottom: 0;
			}
		}
	}
}

.b-mfilters {
	$b: '.b-mfilters';

	&__wrap {
		display: flex;
		background-color: #f3f4f5;
		border-radius: 2px;
		border: 1px solid #e5e5e5;
		margin-bottom: 15px;

		>div {
			flex: 1;
			text-align: center;
			cursor: pointer;
		}
	}

	&__filter {
		border-right: 1px solid #e5e5e5;
	}

	&__sort {
		label {
			margin-bottom: 0;
		}

		select {
			background: none;
			border: 0;
			margin-left: 2px;
		}
	}

	&__btn {
		padding: 10px;
		padding: 8px;
	}

	@include respond-from($device-sm, none) {
		display: none;
	}
}

.b-categories {
	$b: '.b-categories';
	position: sticky;
	top: 23px;
	padding: 8px;
	background-color: #fff;
	border-radius: 5px;
	z-index: 890;

	#{$b}__head {
		padding: 5px 0 10px;
		margin-bottom: 8px;
		font-size: rem(13px);
		color: #000;
		font-weight: 600;
		border-bottom: 1px solid #E8E7EB;

		.icon {
			position: relative;
			top: -2px;
			margin-right: 4px;
		}
	}

	#{$b}__list {
		>ul {
			list-style: none;
			margin: 0;

			>li {
				margin-bottom: 2px;

				&.active a,
				a &:hover {
					&:after {
						content: '\f105';
					}

					background-color: lighten($theme-color, 50);
				}
			}

			i {
				display: inline-block;
				width: 20px;
				text-align: center;
				margin-right: 5px;

				img {
					max-width: 100%;
				}
			}

			a {
				position: relative;
				display: block;
				padding: 4px;
				font-size: rem(13px);
				color: #454545;
				text-decoration: none;
				transition: all 0.2s ease-in;

				&:after {
					content: '';
					position: absolute;
					font: normal normal normal 14px/1 FontAwesome;
					right: 5px;
					top: 6px;
					font-size: rem(15px);
				}
			}
		}
	}

	#{$b}__grid {
		a {
			text-decoration: none;
		}

		&-item {
			display: block;
			margin: 0 auto;
			max-width: 120px;
		}

		&-thumb {
			padding-top: 100%;
			background-size: cover;
			background-position: center center;
			margin-bottom: 3px;
		}

		ul {
			display: grid;
			grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
			list-style: none;
			grid-gap: 10px;
			margin-left: 0;

			li {
				text-align: center;

				img {
					max-width: 100%;
				}
			}
		}

		@include respond-from($device-sm, none) {
			&-item {
				max-width: 120px;
			}

			ul {
				grid-template-columns: repeat(auto-fit, minmax(90px, 1fr));
				grid-gap: 15px;
			}
		}
	}

	#{$b}__block {
		display: none;
		position: absolute;
		width: 200%;
		left: 100%;
		top: 0;
		bottom: 0;
		border-radius: 5px;
		background-color: #fff;
		border-left: 1px solid #E8E7EB;
	}

	#{$b}__seg,
	#{$b}__brands {
		position: absolute;
		top: 0;
		bottom: 0;
		padding: rem(43px) rem(20px) rem(20px);
		overflow: auto;

		>ul {
			margin: 0;
			padding: 0;
			list-style: none;
		}
	}

	#{$b}__seg {
		left: 0;
		right: 50%;

		>ul {
			>li {
				margin-bottom: 4px;
				font-size: rem(13px);

				a {
					display: block;
					padding: rem(5px) rem(10px);
					transition: all 0.2s ease-in;
					text-decoration: none;

					&:hover {
						background-color: lighten($theme-color, 55);
					}
				}
			}

			a {
				color: #494747;
				font-family: $font-m;
			}
		}
	}

	#{$b}__brands {
		background-color: #F2F4F7;
		left: 50%;
		right: 0;

		ul {
			li {
				margin-bottom: 2px;
				font-size: rem(13px);
			}

			a {
				color: #494747;
				font-family: $font-m;
			}
		}
	}
}

.navbar {
	.b-categories {
		$b: '.b-categories';
		position: absolute;
		bottom: -12px;
		left: 0;
		top: auto;
		right: 0;
		z-index: 109;

		#{$b}__head {
			padding: 0;
			margin-bottom: 0;
			border-bottom: 0;

		}

		#{$b}__list {
			display: none;
		}

		#{$b}__block {
			display: none;
		}

		&--is-open {
			#{$b}__list {
				display: block;
				position: absolute;
				left: 0;
				right: 0;
				top: 100%;
				height: 400px;
				background: #fff;
			}

			#{$b}__block {
				position: absolute;
				left: 100%;
				right: 0;
				top: 100%;
				height: 400px;
				background: #fff;
			}
		}
	}
}

.b-prod-card {
	$b: '.b-prod-card';
	position: relative;
	background-color: #fff;
	margin-bottom: 15px;

	&__thumb {
		position: relative;
		padding: 5px;

		#{$b}__like {
			cursor: pointer;
			position: absolute;
			top: 10px;
			right: 10px;
			z-index: 5;

			&.is-liked {
				background-color: $theme-color;
			}
		}
	}

	&__tags {
		position: absolute;
		display: flex;
		flex-flow: row wrap;
		left: 10px;
		right: 35px;
		top: 10px;
		z-index: 5;
	}

	&__offer {
		position: absolute;
		top: 50%;
		margin-top: -10px;
		right: 0;
		background-color: #D2202E;
		padding: 5px 8px 5px 14px;
		border-radius: 25px 0 0 25px;
		pointer-events: none;
		color: #fff;
		z-index: 6;
	}

	&__tag {
		margin-right: 5px;
		margin-bottom: 5px;

		span {
			display: inline-block;
			font-size: rem(11px);
			line-height: 1;
			letter-spacing: rem(0.46px);
			color: #000;
			background-color: #FAD553;
			padding: rem(3px) rem(8px);
			border-radius: 15px;
		}

		&-offer {
			span {
				background-color: #D2202E;
				color: #fff;
				font-family: $font-m;
			}
		}
	}

	&__like {
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #C7CCD4;
		color: #fff;
		font-size: rem(10px);
		width: 20px;
		height: 20px;
		border-radius: 15px;
	}

	&__image {
		display: block;
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;
		min-height: 100px;
		padding-top: 30%;
		transform: scale(0.9);
		transition: all 0.5s ease-in;
	}

	&__main {
		padding: 15px 10px;
	}

	&__meta {
		display: flex;
		font-size: 11px;
		margin-bottom: 7px;
	}

	&__cat {
		margin-right: auto;
		color: #8F9094;
	}

	&__stock {
		color: #DD4B4B;

		&.in-stock {
			color: #4DC24B;
		}
	}

	&__info {
		a {
			color: #1A46BB;
			font-family: $font-m;

			&:hover {
				color: darken(#1A46BB, 10);
			}
		}
	}

	&__title {
		color: #1A46BB;
	}

	&__title,
	&__big-title {
		color: #1A46BB;
		margin-bottom: 6px;
		letter-spacing: rem(0.3px);
	}

	&__big-title {
		display: none;
		flex: 1;
	}

	&__price {
		display: flex;
		align-items: flex-end;
		font-family: $font-m;
	}

	&__price-before {
		font-size: rem(12px);
		color: #8F9094;
		margin-right: auto;
		text-decoration: line-through;
	}

	&__price-val {
		font-size: rem(16px);
		color: #000;
	}

	&__count {
		color: #D2202E;
		font-size: rem(13px);
		letter-spacing: rem(0.28px);
		font-family: $font-m;
	}

	&__timeout {
		color: #8F9094;
		font-size: rem(13px);
		letter-spacing: rem(0.54px);

		span {
			font-family: $font-m;
			color: #000;
		}
	}

	&__footer {
		display: flex;
		flex-flow: wrap;
		align-items: center;
		justify-content: space-between;
		border-top: 1px solid #E8E7EB;
		padding-top: 10px;
		margin-top: 10px;
	}

	&__compare {
		display: flex;
		align-items: center;
		color: #8F9094;
		font-size: rem(12px);
		letter-spacing: rem(0.5px);
		margin-right: auto;
		margin-top: 5px;
		margin-bottom: 5px;

		label {
			margin-bottom: 0;
		}
	}

	&__action {
		.btn {
			font-size: rem(12px);
			padding: rem(5px) rem(9px);
			letter-spacing: 0;
			margin-bottom: 0;
		}
	}

	&:hover {
		.b-prod-card__image {
			transform: scale(1);
			transition: all 1s ease-out;
		}
	}

	@include respond-from(none, $device-sm) {
		&__compare {
			.checkbox-round {
				display: none;
			}
		}

		&__meta {
			flex-flow: column;
		}

		&__title {
			height: 51px;
			overflow: hidden;
		}
	}

	@include respond-from($device-sm, none) {
		&__title {
			height: rem(60px);
			overflow: hidden;
		}
	}

	@include respond-from($device-xl, none) {
		&__action {
			.btn {
				padding: rem(5px) rem(11px);
				letter-spacing: rem(0.5px);
			}
		}
	}

}

.b-blog-card {
	height: 100%;
	padding-bottom: 20px;


	&:after {
		content: '';
		display: block;
	}

	&__wrap {
		display: block;
		height: 100%;
		background-color: #fff;

		&:hover {
			text-decoration: none;
		}
	}

	&:hover {
		.b-blog-card {
			&__img {
				transform: scale(1.2);
			}
		}
	}

	&__thumb {
		overflow: hidden;
		background-color: #f2f2f2;
	}

	&__img {
		padding-top: 48%;
		background-size: cover;
		background-position: center center;
		transition: all 1s ease-in;
	}

	&__tag {
		display: flex;
		font-size: rem(12px);
		color: #8F9094;
		margin-bottom: 7px;
	}

	&__category {

		&:before {
			margin: 0 5px 0 8px;
			content: '/';
		}
	}

	&__title {
		font-size: rem(14px);
		line-height: 1.2;
		color: #1A46BB;
		letter-spacing: rem(0.3px);
	}

	&__info {
		padding: 20px;
	}

	/**
	 * Variants
	 */
	&--list {
		height: auto;

		.b-blog-card {
			margin-bottom: 12px;

			&__wrap {
				display: flex;
				height: auto;
				border-radius: 3px;
				overflow: hidden;
			}

			&__thumb {
				flex: 3;
			}

			&__img {
				height: 100%;
			}

			&__info {
				flex: 5;
				padding: 10px;
			}

			&__title {
				height: rem(51px);
				overflow: hidden;
				text-overflow: ellipsis;
			}

			@include respond-from($device-md, none) {
				&__info {
					padding: 20px;
				}

				&__title {
					height: rem(34px);
				}
			}
		}
	}
}

.b-blog-share {

	&__title {
		text-align: center;
		font-size: rem(13px);
		color: #515151;
		letter-spacing: rem(0.23px);
		margin-bottom: 6px;
	}

	&__list {
		ul {
			display: flex;
			justify-content: center;
			list-style: none;
			text-align: center;
			margin-left: 0;

			>li {
				margin: 0 5px;
			}
		}
	}

	@include respond-from($device-sm, none) {
		position: sticky;
		top: 50px;
		margin-top: 170px;
		margin-bottom: 30px;

		&__list {
			ul {
				flex-flow: column;

				>li {
					margin: 0 0 10px;
				}
			}
		}
	}
}

.b-wishlist {
	$b: '.b-wishlist';

	#{$b}__item {
		display: flex;
		flex-flow: column;
		border-radius: 3px;
		background-color: #fff;
		margin-bottom: 12px;
	}

	#{$b}__main {
		display: flex;
		flex-flow: column;
	}

	#{$b}__thumb {
		position: relative;
		padding: 15px 15px 0;
		min-width: 100px;

		#{$b}__like {
			cursor: pointer;
			position: absolute;
			top: 10px;
			right: 10px;
		}
	}

	#{$b}__tag {
		position: absolute;
		left: 10px;
		top: 10px;

		span {
			display: inline-block;
			font-size: 11px;
			line-height: 1;
			letter-spacing: rem(0.46px);
			color: #000;
			background-color: #FAD553;
			padding: 3px 8px;
			border-radius: 15px;
		}
	}

	#{$b}__image {
		display: block;
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;
		min-height: 100px;
		height: 100%;
		padding-top: 30%;
	}

	#{$b}__info {
		display: flex;
		flex-flow: column;
		justify-content: center;
		padding: 15px;
		border-bottom: 1px solid #E8E7EB;
	}

	#{$b}__cat {
		color: #8F9094;
		font-size: rem(12px);
		font-family: $font-m;
	}

	#{$b}__title {
		font-size: rem(14px);
		font-family: $font-m;
	}

	#{$b}__meta {
		padding: 15px;
		flex: 1;
	}

	#{$b}__stock {
		margin-bottom: 8px;
	}

	#{$b}__price-before {
		font-size: rem(12px);
		color: #8F9094;
		margin-right: auto;
		text-decoration: line-through;
		display: inline-block;
	}

	#{$b}__price-val {
		font-size: rem(16px);
		color: #000;
		font-family: $font-m;
	}

	#{$b}__actions {
		display: flex;
		margin-top: 15px;

		.btn {
			flex: none;
			margin-right: 10px;
			padding: 10px;
            text-transform: uppercase;
		}
	}

	@include respond-from($device-sm, none) {
		#{$b}__item {
			flex-flow: row;
		}

		#{$b}__main {
			flex-flow: row;
			width: 56%;
		}

		#{$b}__thumb {
			width: 40%;
			padding: 15px;
		}

		#{$b}__info {
			padding: 0 10px 0 0;
			width: 60%;
			border-bottom: 0;
			border-right: 1px solid #E8E7EB;
		}

		#{$b}__meta {
			padding: 15px;
		}
	}

	@include respond-from($device-md, none) {
		#{$b}__main {
			width: 66%;
		}
	}
}

.b-video {
	&__holder {
		position: relative;
		max-width: 1000px;
		margin: 0 auto 30px;
		padding-top: 45%;
	}

	iframe {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
	}
}

.b-rating {
	$b: '.b-rating';
	$b-color: #388E3C;

	display: flex;

	i {
		color: $b-color;

		&.fa-star-o {
			color: #E6E9ED;
		}
	}

	#{$b}__count {
		// display: flex;
		width: 120px;
		margin-right: 20px;
	}

	#{$b}__avg {
		font-size: rem(38px);
		letter-spacing: rem(0.18px);
		font-weight: 600;
	}

	#{$b}__reviews {
		color: #454545;
		font-size: rem(14px);
		letter-spacing: rem(0.58px);
	}

	#{$b}__bars {
		flex: 1;
	}

	#{$b}__bar {
		display: flex;
		margin-top: 8px;
		margin-bottom: 8px;
	}

	#{$b}__stars {
		margin-right: 10px;

		i {
			margin-right: 3px;
		}
	}

	#{$b}__line {
		flex: 1;
		display: flex;
		background-color: #EFF0F5;
		height: 11px;
		margin-top: 6px;
		margin-right: 10px;

		.rail {
			display: inline-block;
			height: 11px;
			background-color: $b-color;
		}
	}

	#{$b}__num {
		font-size: rem(14px);
		color: #454545;
	}
}

.b-rates {
	&__logo {
		display: inline-block;
		margin-right: 40px;
		width: 100px;
		text-align: center;

		img {
			max-width: 100%;
		}
	}

	&__list {
		color: #454545;
		font-size: rem(13px);
		line-height: 22px;
	}
}

.b-cart-item {
	$b: '.b-cart-item';


	display: flex;
	align-items: center;
	margin-bottom: 15px;

	#{$b}__thumb {
		flex: 0 0 140px;
		padding: 5px;
		align-self: stretch;
	}

	#{$b}__image {
		height: 100%;
		background-size: contain;
		background-position: center center;
		background-repeat: no-repeat;
	}

	#{$b}__info {
		flex: 1;
		padding: 6px;
	}

	#{$b}__title {
		font-size: rem(14px);
		font-family: $font-m;
		color: $theme-color;
		letter-spacing: rem(0.5px);
		margin-bottom: 13px;
	}

	#{$b}__price {
		font-size: rem(13px);
		color: #7B7C7F;
	}

	#{$b}__qty {

		input {
			font-size: rem(13px);
			color: #7B7C7F;
			width: 30px;
			padding: 0;
			border: 0;
			text-align: right;
		}
	}

	#{$b}__amount {
		font-size: rem(14px);
		color: #000000;
		margin-left: 5px;
		font-family: $font-m;
	}

	#{$b}__remove {
		flex: none;
		padding: 10px;
		color: #B3BAC4;
		cursor: pointer;
	}
}

.b-order {
	$b: '.b-order';
	background-color: #fff;
	border-radius: 3px;
	overflow: hidden;
	margin-bottom: 8px;

	&:last-of-type {
		margin-bottom: 15px;
	}

	.small-title {
		font-size: rem(13px);
		font-family: $font-m;
		margin-bottom: 10px;
	}

	.small-text {
		font-size: rem(13px);
		color: #454545;
		line-height: rem(18px);

		&:last-of-type {
			margin-bottom: 0;
		}
	}


	#{$b}__status {
		display: flex;
		flex-flow: row wrap;
		padding: 5px 20px;
		border-bottom: 1px solid #E8E7EB;

		a {
			font-size: rem(14px);
			font-family: $font-m;
		}
	}

	#{$b}__info {
		display: flex;

		>div {
			margin-right: 10px;

		}
	}

	#{$b}__attr {
		margin-right: 15px;
		color: #969696;
		font-size: rem(12px);

		span {
			display: block;
			color: #000000;
			font-family: $font-m;
		}
	}

	#{$b}__more {
		margin-left: auto;
	}

	#{$b}__detail,
	#{$b}__price {
		display: flex;
		align-items: center;
		padding: 10px 0;
	}

	#{$b}__invoice {
		font-size: rem(14px);
		color: #7A7986;
		border-bottom: 1px solid #E8E7EB;
		line-height: 25px;
		padding-bottom: 20px;
		margin-bottom: 20px;
	}

	#{$b}__dl {
		display: flex;
		justify-content: space-between;
	}

	#{$b}__inv-total {
		display: flex;
		justify-content: space-between;
		font-size: rem(18px);
		font-family: $font-m;
	}

	#{$b}__detail,
	#{$b}__address {
		min-width: 58%;
		border-right: 1px solid #E8E7EB;
	}

	#{$b}__address,
	#{$b}__summary {
		padding: 20px;
	}

	#{$b}__price,
	#{$b}__summary {
		min-width: 42%;
	}

	#{$b}__qty {
		flex: 1 0 40%;
	}

	#{$b}__total {
		flex: 1 0 60%;
	}

	#{$b}__col {
		padding-left: rem(15px);
		padding-right: rem(15px);
	}

	#{$b}__thumb {
		max-width: 200px;
		flex: 1 0 40%;
	}

	#{$b}__image {
		padding-top: 30%;
		min-height: 90px;
		background-size: contain;
		background-position: center center;
		background-repeat: no-repeat;
	}

	#{$b}__label {
		font-size: rem(12px);
		color: #8F9094;
		font-family: $font-m;
	}

	#{$b}__val {
		font-size: rem(16px);
		color: #000;
		font-family: $font-m;
	}

	#{$b}__title {
		font-size: rem(14px);
		color: $theme-color;
		font-family: $font-m;
	}

	#{$b}__row {
		border-bottom: 1px solid #e8e7eb;

		&:last-of-type {
			border-bottom: 0;
		}
	}

	@include respond-from($device-md, none) {
		#{$b}__row {
			display: flex;
		}

		#{$b}__attr {
			span {
				display: inline;
			}
		}
	}
}

.b-steps {
	$b: '.b-steps';
	display: flex;
	margin: 40px auto 40px;
	justify-content: center;

	#{$b}__item {
		padding-top: 30px;
		position: relative;
		flex: 1;
		max-width: 170px;
		text-align: center;

		&:before {
			position: absolute;
			content: '';
			width: 14px;
			height: 14px;
			left: 50%;
			margin-top: -30px;
			margin-left: -7px;
			border-radius: 50%;
			background-color: #B3BAC4;
			margin-bottom: 25px;
		}

		+#{$b}__item {
			&:after {
				position: absolute;
				top: 6px;
				display: block;
				content: '';
				width: 100%;
				left: -50%;
				height: 3px;
				background-color: #B3BAC4;
				z-index: -1;
			}
		}

		&.active {

			&:before,
			&:after {
				background-color: #28A951;
			}
		}
	}

	#{$b}__name {
		font-size: rem(13px);
		color: #454545;
		font-family: $font-m;
		letter-spacing: rem(0.5px);
	}
}

.b-req-quote {
	$b: '.b-req-quote';
	background-color: #fff;
	padding: 50px 0;

	fieldset {
		margin-bottom: 15px;
	}

	.form-control {
		border-color: #D5D8DD;
		font-size: rem(13px);
		color: #7B7C7F;
		letter-spacing: rem(0.54px);
		padding: 10px 17px;
		height: auto;
		resize: vertical;
	}

	&__form {
		max-width: 750px;
		margin: 0 auto;
	}

	&__head {
		margin-bottom: 50px;
	}
}

.b-brands {
	display: flex;

	ul {
		list-style: none;
		margin: 0;
	}

	&__links {


		li {
			margin-bottom: 2px;
		}

		i {
			display: inline-block;
			width: 20px;
			text-align: center;
			margin-right: 5px;

			img {
				max-width: 100%;
			}
		}

		strong {
			font-size: rem(13px);
			font-weight: 600;
			padding: 4px;
		}

		a {
			display: block;
			padding: 4px;
			font-size: rem(13px);
			color: #454545;
		}

	}


	&__categories {
		width: 220px;
		padding: 10px;
	}

	&__block {

		>ul {
			width: 100%;
			height: 100%;

			>li {
				display: none;
				position: absolute;
				left: 220px;
				right: 0;
				top: 0;
				bottom: 0;

				&.active {
					display: flex;
				}
			}
		}
	}

	&__list {
		padding: 10px;
		position: absolute;
		left: 0;
		right: 50%;
		top: 0;
		bottom: 0;
	}

	&__grid {
		padding: 10px;
		position: absolute;
		overflow: auto;
		left: 37%;
		right: 0;
		top: 0;
		bottom: 0;
		background-color: #F2F4F7;

		>ul {
			display: flex;
			flex-flow: row wrap;
			width: 100%;

			li {
				margin: 6px;
				flex: 1 0 40%;
				max-width: calc(50% - 12px);

				img {
					max-width: 100%;
				}

				a {
					background-color: #fff;
					border-radius: 5px;
					height: 50px;
					display: flex;
					align-items: center;
					justify-content: center;
					padding: 3px 6px;
				}
			}
		}
	}
}

.b-prod-card__price-koko{
    padding: 5px 0px;
    font-size: 12px;
    color: #8F9094;
    font-weight: 500;

    &.product-land{
        display: flex;
        padding-bottom: 20px;
        align-items: center;
        gap: 8px;
    }
    b{
        color: #707175;
        font-weight: 500;
    }
}
