.special-offer-tab {
	padding-bottom: 13px;
	margin-bottom: 24px;

	.nav-link {
		padding: 0;
		background-color: transparent !important;
		border: none;
		font-family: $font-m;
		font-size: rem(16px);
		letter-spacing: 0.67px;
		color: #505154;
		text-transform: capitalize;
		margin-right: 39px;

		&:last-child {
			margin-right: 0;
		}

		&.active {
			color: #1B40A1;
		}
	}
}

.l-quote__card{
	ul{
		li{
			img{
				margin-right: 15px;
			}
		}
	}
}

