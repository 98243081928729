/* --------------------------------
 *  Common
 *  for common styles and library styles overwriting
 -------------------------------- */
.btn {
	font-size: rem(12px);
	letter-spacing: rem(0.5px);
	line-height: 1.3;
	padding: 10px 25px;
	border-radius: 2px;

	.icon {
		margin-right: 4px;
	}

	&-sm {
		padding: 6px 15px;
	}

	&-primary {
		background-color: $theme-color;
		color: #fff;
		text-transform: uppercase;

		&:hover {
			background-color: darken($theme-color, 5);
		}
	}

	&-secondary {
		background-color: $secondary-color;
		color: #fff;

		&:hover {
			background-color: darken($secondary-color, 5);
			color: #fff;
		}
	}

	&-default {
		background-color: #333333;
		color: #fff;

		&:hover {
			background-color: darken(#333, 5);
			color: #fff;
		}
	}

	&-outline {
		border: 1px solid $theme-color;
		color: $theme-color;

		&:hover {
			border: 1px solid $theme-color;
			background-color: rgba(lighten($theme-color, 50), 0.6);
			color: darken($theme-color, 15);
		}
	}

	&-text {
		padding: 0 10px;
		background: none;
		border: 0;
		color: #1A46BB;
	}

	&-disabled {
		background-color: #B3BAC4;
		color: #fff;
	}

	&-social {
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: rem(15px);
		color: #fff;
		width: 100%;
		margin-bottom: 15px;

		.icon {
			margin-right: 6px;
			margin-left: -10px;
			margin-top: -2px;
		}

		&:hover {
			color: #fff;
		}
	}

	&-ib {
		display: inline-block;
		width: auto;
	}

	&-fb {
		background-color: #1877F2;

		&:hover {
			background-color: darken(#1877F2, 10);
		}
	}

	&-fb-light {
		background-color: #E7F0F8;
		color: #7A7986;

		&:hover {
			color: #7A7986;
			background-color: darken(#E7F0F8, 10);
		}
	}

	&-gl {
		background-color: #2A3B50;

		&:hover {
			background-color: darken(#2A3B50, 10);
		}
	}
}

.dropdown-toggle {
	&::after {
		position: relative;
		top: 2px;
	}
}

.chip {
	display: inline-block;
	padding: 4px 8px;
	font-size: rem(12px);
	border-radius: 10px;
	line-height: 1;

	&-success {
		color: #fff;
		background-color: #388E3C;
	}

	&-info {
		padding: 6px 10px;
		background-color: #fff;
		font-size: rem(12px);
		color: #454545;

		.chip-label {
			color: #B3B5BA;
			margin-right: 4px;
		}

		.chip-close {
			color: #B3B5BA;
			margin-left: 2px;
			cursor: pointer;
		}
	}
}

.text-green {
	color: #4DC24B;
}

.radio-round {
	position: relative;
	margin-bottom: 6px;

	label {
		display: block;
		cursor: pointer;
		padding-left: 25px;
		margin-bottom: -2px;
	}

	.bullet {
		background-color: #fff;
		border: 1px solid #ccc;
		border-radius: 50%;
		cursor: pointer;
		height: 14px;
		left: 0;
		position: absolute;
		top: 50%;
		margin-top: -7px;
		width: 14px;

		&:after {
			border-top: none;
			border-right: none;
			content: "";
			width: 6px;
			height: 6px;
			background-color: #fff;
			top: 3px;
			left: 3px;
			opacity: 0;
			border-radius: 50%;
			position: absolute;
		}
	}

	input[type="radio"] {
		visibility: hidden;
		display: none;

		&:checked+.bullet {
			background-color: $theme-color;

			&:after {
				opacity: 1;
			}
		}
	}

}

.checkbox-round {
	position: relative;

	label {
		display: block;
		cursor: pointer;
		padding-left: 21px;
		margin-bottom: 4px;
	}

	.bullet {
		background-color: #fff;
		border: 1px solid #ccc;
		border-radius: 50%;
		cursor: pointer;
		height: 14px;
		left: 0;
		position: absolute;
		top: 1px;
		width: 14px;

		&:after {
			border: 2px solid #fff;
			border-top: none;
			border-right: none;
			content: "";
			height: 4px;
			left: 2px;
			opacity: 0;
			position: absolute;
			top: 3px;
			transform: rotate(-45deg);
			width: 8px;
		}
	}

	input[type="checkbox"] {
		visibility: hidden;
		display: none;

		&:checked+.bullet {
			background-color: #C7CCD4;
			border-color: #C7CCD4;

			&:after {
				opacity: 1;
			}
		}
	}

	&.checkbox-primary {
		font-size: rem(13px);
		color: #454545;

		.bullet {
			height: 16px;
			width: 16px;

			&::after {
				top: 4px;
				left: 3px;
			}
		}


		input[type=checkbox] {
			&:checked+.bullet {
				background-color: $theme-color;
			}
		}
	}
}

.breadcrumb {
	background: none;
	padding: 0;
	margin: 15px auto 20px;

	>li {
		position: relative;
		margin-right: 10px;
		padding-right: 10px;

		&:after {
			content: '/';
			position: absolute;
			right: 0;
		}

		&:last-of-type {
			&::after {
				content: '';
			}
		}
	}
}

.container-max-lg {
	@include respond-from($device-xl, none) {
		max-width: $device-lg;
	}
}

.row {
	&.pad-15 {

		margin-left: -7.5px;
		margin-right: -7.5px;

		>div {
			padding-left: 7.5px;
			padding-right: 7.5px;
		}
	}
}

.input-group {
	i {
		width: 16px;
		text-align: center;
	}

	.input-group-text {
		background-color: #fff;
		width: 40px;
		justify-content: center;
	}

	.input-group-prepend {
		.form-control {
			border-left: 1px solid #ced4da;
		}

	}

	>.form-control {
		padding-left: 5px;
	}

	&--s1 {
		.input-group-text {
			width: auto;
		}

		>.form-control {
			border-left: 1px solid #ced4d9;
		}
	}
}

.input-ib {
	display: inline-block;
	margin-right: 20px;
}

.radio-img {
	input {
		display: none;
	}

	&__label {
		padding: 5px 10px;
		border: 1px solid #D7DADE;
		margin-right: 8px;
		border-radius: 2px;
	}

	input:checked {
		&+.radio-img__label {
			border: 1px solid $theme-color;
		}
	}
}

.select2-container--default {
	.select2-selection--single {
		background-color: #fff;
		border: 1px solid #D8D8D8;
		border-radius: 2px;

		.select2-selection__arrow {
			width: 15px;
		}
	}
}

.tns-outer {
	>button {
		display: none;
	}
}

.table td,
.table th {
	border-top: 0;
	padding: rem(8px) rem(15px);
}

.table thead th {
	border-bottom: 0;
}

.table-striped {
	background-color: #fff;
}

.table-striped tbody tr:nth-of-type(odd) {
	background-color: #EFF1FC;
}

.icon-list {
	list-style: none;
	padding-left: 0;
	margin-left: 0;

	>li {
		position: relative;
		margin-top: 12px;
		margin-bottom: 12px;
		padding-left: 30px;
	}

	.icon {
		position: absolute;
		left: 0;
		top: -2px;
		display: inline-block;
		width: 20px;
		margin-right: 10px;
		text-align: center;
	}
}

.rating {
	display: flex;
	align-items: center;

	.rating-input {
		position: relative;
		height: 50px;
		line-height: 50px;
		font-size: 30px;
		margin-right: 15px;
	}

	.rating-text {
		font-size: rem(12px);
		color: #8F9094;
	}

	label {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		cursor: pointer;

		&:last-child {
			position: static;
		}

		&:nth-child(1) {
			z-index: 5;
		}

		&:nth-child(2) {
			z-index: 4;
		}

		&:nth-child(3) {
			z-index: 3;
		}

		&:nth-child(4) {
			z-index: 2;
		}

		&:nth-child(5) {
			z-index: 1;
		}

		input {
			position: absolute;
			top: 0;
			left: 0;
			opacity: 0;
		}

		.icon {
			float: left;
			color: transparent;
		}

		&:last-child .icon {
			color: #EFF0F5;
		}
	}

	&:not(:hover) label input:checked~.icon,
	&:hover label:hover input~.icon {
		color: #388E3C;
	}

	label input:focus:not(:checked)~.icon:last-child {
		color: #000;
		text-shadow: 0 0 5px #388E3C;
	}

}

.rating-list {
	font-size: rem(13px);
	letter-spacing: rem(0.54px);
	color: #454545;

	&__item {
		margin-bottom: 40px;
	}

	&__meta {
		display: flex;
		justify-content: space-between;
		margin-bottom: 10px;
		color: #8F9094;

		.chip {
			margin-right: 5px;
		}
	}

	&__subject {
		font-size: rem(16px);
		color: #000;
		letter-spacing: rem(0.67px);
		font-weight: 600;
		margin-bottom: 12px;
	}

}

.pagination {
	.page-item {

		.page-link {
			color: $theme-color;
			background: none;
			border: 0;
			margin: 0 2px;
			width: 26px;
			height: 26px;
			padding: 5px;
			text-align: center;
		}

		&.active {
			.page-link {
				background-color: $theme-color;
				color: #fff;
				border-radius: 50%;
				width: 26px;
				height: 26px;
			}
		}

		&:first-of-type {
			margin-right: 20px;

			.page-link {
				width: auto;
			}
		}

		&:last-of-type {
			margin-left: 20px;

			.page-link {
				width: auto;
			}
		}

		&.disabled {
			.page-link {
				color: #8F9094;
			}
		}
	}
}

.form-subscribe {
	display: flex;
	border: 1px solid #D5D8DD;
	background-color: #fff;
	padding: 3px;
	border-radius: 2px;
	margin-bottom: 30px;

	.btn {
		padding: 5px 15px;
		border-radius: 1px;
		min-width: 100px;
	}

	.form-control {
		border: 0;
		height: 34px;
		font-size: rem(13px);
		letter-spacing: rem(0.54px);
	}
}

.b-text-link {
	i {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		width: 25px;
		height: 25px;
		border-radius: 50%;
		background-color: #1B40A1;
		color: #fff;
		margin-right: 5px;
	}
}
