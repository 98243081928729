/* --------------------------------
 *  Pages
 *  for page specific styles
 *  named sections should be styled under relevant page
 -------------------------------- */
.special-offers {
	.l-card-list__grid {

		.swiper-prev,
		.swiper-next {
			position: absolute;
			top: 50%;
			width: 20px;
			height: 20px;
			border-radius: 50%;
			background-color: #fff;
		}

		.swiper-prev {
			left: 0;
		}

		.swiper-next {
			right: 0;
		}
	}

	.l-card-list__col {
		flex: 1 0 auto;
	}
}

.p-about {
	.l-brands {
		h3 {
			color: #000;
			text-align: center;
		}
	}
}

.career-card {

	display: block;
	background-color: #fff;
	padding: 15px;
	color: #7C7C81;
	cursor: pointer;
	transition: all 0.3s ease-in;
	margin-bottom: 25px;
	height: 100%;

	&:hover {
		box-shadow: rgba(#33324D, 0.25) -1px 27px 37px -9px;

		.career-card {
			&__title {
				color: $theme-color;
			}
		}
	}

	&:after {
		content: '';
		display: block;
		margin-bottom: 25px;
	}

	&__date {
		font-size: rem(14px);

		span {
			margin-left: 3px;
		}
	}

	&__title {
		color: #000000;
		font-size: rem(18px);
		min-height: rem(54px);
		margin-bottom: 15px;
		transition: all 0.3s ease-in;
	}

	&__location {
		min-height: rem(42px);

		span {
			color: $theme-color;
			transition: all 0.3s ease-in;
		}
	}
}
